export const setLanguage = (payload: 'en' | 'ja') => {
  console.log('Triggered setLanguage with Payload', payload);
  return {
    type: 'SET_LANGUAGE',
    payload,
  };
};

export const toggleDarkMode = (payload: boolean) => {
  console.log('Triggered toggleDarkMode with Payload', payload);
  return {
    type: 'TOGGLE_DARK_MODE',
    payload,
  };
};

export const setEnvVariables = (payload: any) => {
  console.log('Triggered setEnvVariables with Payload', payload);
  return {
    type: 'SET_ENV_VARIABLES',
    payload,
  };
};

export const clearUIData = (payload: any) => {
  console.log('Triggered clearUIData with Payload', payload);
  return { type: 'CLEAR_UI_DATA', payload };
};

export const toggleNotification = (flag: boolean, message: string) => {
  return {
    type: 'TOGGLE_NOTIFICATION',
    value: flag,
    payload: message,
  };
};
