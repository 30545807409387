import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { setDarkTheme, setLanguage, updateWidget, updatePhoneValidation } from '../Store/actions/customerInfoActions';
import countries from '../services/countries';
import languages from '../services/languages';
import parsePhoneNumber from 'libphonenumber-js';
import axios from 'axios';

interface PersonalInfo {
  Title: any;
  Name: any;
  'Highest Support Plan': any;
  'Email Address': any;
  'Renga ID': any;
  Phone: any;
  Country: any;
  Language: any;
  'Active Subscription Plan'?: any;
  'Value Add Action'?: any;
  offerEligibility?: any;
  'In-Queue Prompts'?: any
}

const ASPLIST = ['Business', 'Enterprise', 'Elite'];

const useAttributeData = (contextData: any, darkMode: any, isCIP: any, language: any) => {
  const dispatch = useDispatch();
  const { currentContactId, isConversationActive, userPersisted, orderQuote, customerOfferList }: any = useSelector((state: any) => state.customerInfo);
  const { jcAuthData, ocPlatformData, ocAppData, jcMidConvData } = contextData;
  const phoneOrigin = ['outbound-phone', 'inbound-phone', 'bot-to-phone'];
  const isPhoneOrigin = phoneOrigin.includes(ocAppData.originForCurrentConversation);
  const allPhoneOrigin = ['outbound-phone', 'inbound-phone', 'bot-to-phone', 'chat-to-phone'];

  // const getValidatedPhoneNumber = (phoneNumber: string) => {
  //   try {
  //     const parsedPhoneInfo = parsePhoneNumber(phoneNumber);
  //     const isPhoneValid = parsedPhoneInfo ? parsedPhoneInfo?.isValid() : false
  //     const validatedPhoneNumber = isPhoneValid ? parsedPhoneInfo?.number : phoneNumber
  
  //     const phoneInfo = {
  //       valid: isPhoneValid
  //     }
  //     dispatch(updatePhoneValidation(phoneInfo))
      
  //     return validatedPhoneNumber
  //   } catch(Error) {
  //     console.log('CIP phone validation were faled with Error : ', Error);
  //     const phoneInfo = {
  //       valid: false,
  //     }
  //     dispatch(updatePhoneValidation(phoneInfo))
  //     return phoneNumber
  //   }
    
  // }

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(setDarkTheme(darkMode));
      dispatch(setLanguage(language));
    }
    return () => {
      isMounted = false;
    };
  }, [darkMode, language]);
  // NOSONAR
  useEffect(() => {
    let isMounted = true;
    try {
      if (isMounted && jcAuthData && jcMidConvData && ocPlatformData) {
        const isAttributeAvailable = (attribute: string) => (attribute ? [attribute] : []);
        const isNameAvailable = jcAuthData.firstName ? [`${jcAuthData.firstName} ${jcAuthData.lastName}`] : [];
        const countryWithCode = jcAuthData.country ? jcAuthData.country : jcAuthData.countryCode ? jcAuthData.countryCode : jcAuthData.surfaceIpRegion;
        const country = countries[countryWithCode];
        const languageData = languages[`${jcAuthData.language ? jcAuthData.language : jcAuthData.languageCode}_` + `${countryWithCode}`];
        const isReturnCustomer = jcAuthData.returningCustomerType && !jcAuthData.returningCustomerType.toLowerCase().includes('none');
        const isReturnString = isReturnCustomer ? ['RETURN'] : [];
        const isCall = ocPlatformData.callInfo?.initialContactId && ocPlatformData.chatInfo.contactId;

        const isAllPhoneOrigin = allPhoneOrigin.includes(ocAppData.originForCurrentConversation);
        const { callInfo: { handoffCount: pHandoffCount = '' } = {} } = ocPlatformData;
        const { closedConversation = '', closedPhoneCall = '' } = ocAppData;
        const isActive = !(closedConversation && closedPhoneCall);
        const callHandoffCount =
          isActive && isAllPhoneOrigin
            ? pHandoffCount
              ? [pHandoffCount]
              : undefined
            : !isActive && isAllPhoneOrigin
            ? jcAuthData.handoffCount
              ? [jcAuthData.handoffCount]
              : undefined
            : undefined;
        console.log('CIP callHandoffCount', callHandoffCount, isActive, isAllPhoneOrigin, pHandoffCount);
        let chatId = [];
        let phoneId = [];
        if (isPhoneOrigin) {
          phoneId = isAttributeAvailable(ocPlatformData.chatInfo.contactId);
        } else if (isCall) {
          chatId = isAttributeAvailable(ocPlatformData.chatInfo.contactId);
          phoneId = isAttributeAvailable(ocPlatformData.callInfo.initialContactId);
        } else if (ocAppData.originForCurrentConversation === 'inbound-chat') {
          chatId = isAttributeAvailable(ocPlatformData.chatInfo.contactId);
          phoneId = isAttributeAvailable(jcAuthData.linkedConversationId);
        } else if (ocAppData.originForCurrentConversation === 'chat-to-phone') {
          chatId = isAttributeAvailable(jcAuthData.linkedConversationId);
          phoneId = isAttributeAvailable(ocPlatformData.chatInfo.contactId);
        }
        // if customerId is same as currentContactId then Anonymous should display
        const isAnonymous = jcAuthData.customerId === currentContactId;
        const rengaAnonymous = jcAuthData.customerId && !isAnonymous ? [jcAuthData.customerId] : ['Anonymous'];

        const aspSupportData = jcAuthData.aspSupportData;
        const aspRegion = jcAuthData.aspSupportRegion;
        if (JSON.parse(localStorage.getItem('fastFollowFlags')).ASP && ASPLIST.includes(aspSupportData)) {
          jcAuthData.support_entitlements = [{ highestASP: aspSupportData, aspRegion }];
        }

        console.log('SCIP/CIP QE', contextData, rengaAnonymous);
        const contextualInfo = {
          Title: 'Contextual Info',
          'Queue Path': jcAuthData.queuePath.split(','),
          Website: isAttributeAvailable(jcAuthData.surfaceUrl),
          'CustomerOne Issue': jcAuthData.issueType ? [jcAuthData.issueType] : [],
          'CustomerOne Sub-Issue': jcAuthData.subissueType ? [jcAuthData.subissueType] : [],
          'Bot Intent': jcMidConvData.botIntent ? [jcMidConvData.botIntent] : isReturnString,
          'Bot SubIntent': jcMidConvData.botSubintent
            ? [jcMidConvData.botSubintent]
            : isReturnCustomer || jcAuthData.returningCustomerCaseId
            ? [`Case: ${jcAuthData.returningCustomerCaseId ? jcAuthData.returningCustomerCaseId : 'No Open Case'}`]
            : [],
          // 'Return customer':
          //   jcAuthData.returningCustomerType &&
          //   !jcAuthData.returningCustomerType.includes('None') &&
          //   jcAuthData.returningCustomerCaseId
          //     ? [`${jcAuthData.returningCustomerType} - ${jcAuthData.returningCustomerCaseId}`]
          //     : [],
          Product: jcMidConvData.botProductName ? [jcMidConvData.botProductName] : isAttributeAvailable(jcAuthData.subProductName),
          'Number of Transfers': callHandoffCount && (callHandoffCount?.[0] !== '0') ? callHandoffCount : jcAuthData.transferCount ? [jcAuthData.transferCount] : ['0'],
          Origin: ocAppData?.originForCurrentConversation ? [ocAppData.originForCurrentConversation] : [],
        };
        console.log('CIP callHandoffCount', contextualInfo, callHandoffCount, callHandoffCount ?? jcAuthData.transferCount ? [jcAuthData.transferCount] : ['0']);
        const workflow: any = jcMidConvData.HostContextInfo ? JSON.parse(atob(jcMidConvData.HostContextInfo))[0] : '';
        const startTime: string = ocPlatformData.chatInfo.chatStartTime
          ? ocPlatformData.chatInfo.chatStartTime
          : ocPlatformData.callInfo.callStartTime
          ? ocPlatformData.callInfo.callStartTime
          : '';

        const conversationInfo = {
          Title: 'Conversation Info',
          // 'Current Queue': [jcAuthData.currentQueue],
          // Workflow: workflow.data && workflow.data.workflow ? [workflow.data.workflow] : [],
          Workflow: workflow?.data?.workflow ? [workflow.data.workflow] : [],
          'Start Time': [moment(startTime).format('ddd MMM DD, YYYY hh:mma')],
          Source: ['Adobe'],
          Device: isAttributeAvailable(jcAuthData.deviceFamily),
          Browser: jcAuthData.browser && jcAuthData.browserVersion ? [`${jcAuthData.browser} ${jcAuthData.browserVersion}`] : [],
          OS: isAttributeAvailable(jcAuthData.os),
          'Chat ID': chatId,
          'Phone ID': phoneId,
        };

        if (isCIP) {
          const { modifiedCustomer: { firstName = '', lastName = '', email = '', rengaId = '', contactId = '', phone = '' } = {} } = ocAppData || {};
          const iscontactId = currentContactId === contactId || jcAuthData.linkedConversationId === contactId;
          console.log('CIP modifiedCustomer', ocAppData, customerOfferList);
          //const phoneNumber = iscontactId && phone ? phone : jcAuthData.phone ? jcAuthData.phone : jcAuthData.phoneNumber ? jcAuthData.phoneNumber : '';
          //const validatedPhoneNumber = getValidatedPhoneNumber(phoneNumber)
          const personalInfo: PersonalInfo = {
            Title: 'Personal Info',
            Name: iscontactId && firstName && lastName ? [`${firstName} ${lastName}`] : isNameAvailable,
            'Highest Support Plan': jcAuthData.support_entitlements ? jcAuthData.support_entitlements : [],
            'Email Address': iscontactId && email ? [email] : isAttributeAvailable(jcAuthData.email),
            'Renga ID': iscontactId && rengaId ? [rengaId] : rengaAnonymous,
            //Phone: validatedPhoneNumber.length ? [validatedPhoneNumber] : [],
            Phone: iscontactId && phone ? [phone] : jcAuthData.phone ? [jcAuthData.phone] : jcAuthData.phoneNumber ? [jcAuthData.phoneNumber] : [],
            Country: isAttributeAvailable(country || jcAuthData.surfaceIpRegion),
            Language: [languageData || languages[`${jcAuthData.language}_${jcAuthData.region}`] || 'English'],
            'Active Subscription Plan':
              iscontactId && customerOfferList[0]?.entitlement
                ? customerOfferList[0]?.entitlement
                : jcAuthData.entitlementsList && jcAuthData.entitlementsList.split(';').length > 0
                ? jcAuthData.entitlementsList.split(';')
                : [],
            'Value Add Action': customerOfferList[0]?.features || [],
            'In-Queue Prompts': isAttributeAvailable(jcAuthData.inQueuePromptInterest)
          };
          if (customerOfferList[0]?.cctPro) personalInfo.offerEligibility = customerOfferList[0]?.cctPro === true ? ['CCT-Pro'] : ['---'];

          const CIP_data = [personalInfo, contextualInfo, conversationInfo];

          dispatch(updateWidget(isCIP, CIP_data));
        } else {
          const { linkedCustomer: { firstName = '', lastName = '', email = '', rengaId = '', phone = '' } = {}, entitlement = '' } = userPersisted || {};
          const isrengaAnonymous = rengaId === currentContactId;
          const orderQuoteData = orderQuote || {};
          const splitEntitlementWithColen = jcAuthData.entitlementsList.split(';');
          const splitEntitlementWithcomma = jcAuthData.entitlementsList.split(',');
          console.log('SCIP linkedCustomer', userPersisted, workflow);
          //const phoneNumber = phone ? phone : jcAuthData.phone ? jcAuthData.phone : jcAuthData.phoneNumber ? jcAuthData.phoneNumber : '';          
          //const validatedPhoneNumber = getValidatedPhoneNumber(phoneNumber)
          const SCIP_data = [
            {
              Title: 'Personal Info',
              Name: firstName && lastName ? [`${firstName} ${lastName}`] : isNameAvailable,
              'Highest Support Plan': jcAuthData.support_entitlements ? jcAuthData.support_entitlements : [],
              'Email Address': email ? [email] : isAttributeAvailable(jcAuthData.email),
              'Renga ID': rengaId && !isrengaAnonymous ? [rengaId] : rengaAnonymous,
              //Phone: validatedPhoneNumber.length ? [validatedPhoneNumber] : [],
              Phone: phone ? [phone] : jcAuthData.phone ? [jcAuthData.phone] : jcAuthData.phoneNumber ? [jcAuthData.phoneNumber] : [],
              // jcAuthData.email && jcAuthData.customerType.toLowerCase() === 'unauthenticated'
              Country: isAttributeAvailable(country || jcAuthData.surfaceIpRegion),
              Language: [languageData || languages[`${jcAuthData.language}_${jcAuthData.region}`] || 'English'],
              'Active Subscription Plan': entitlement
                ? entitlement
                : jcAuthData.entitlementsList && jcAuthData.entitlementsList.includes(';') && splitEntitlementWithColen.length > 0 && splitEntitlementWithColen[0]
                ? splitEntitlementWithColen
                : splitEntitlementWithcomma.length > 0 && splitEntitlementWithcomma[0]
                ? jcAuthData.entitlementsList.split(',')
                : [],
                'In-Queue Prompts': isAttributeAvailable(jcAuthData.inQueuePromptInterest)
            },
            {
              Title: 'Quote / Order Details',
              Order: orderQuoteData.Order,
              Quote: orderQuoteData.Quote,
            },
            contextualInfo,
            conversationInfo,
            {
              Title: 'Customer Info',
            },
            {
              Title: 'Customer Interaction Summary',
            },
          ];
          dispatch(updateWidget(isCIP, SCIP_data));
        }
      }
    } catch (error) {
      console.log('SCIP useAttributeData error', error);
    }
    return () => {
      isMounted = false;
    };
  }, [currentContactId, userPersisted, jcAuthData?.transferCount, customerOfferList, orderQuote, ocAppData]);
};

export default useAttributeData;
