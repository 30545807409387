import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Link } from '@adobe/react-spectrum';
import OfferMakeConfirmComponent from '../offer-make-confirm/offer-make-confirm-component';
import { OfferRejectReasonComponent } from '../offer-rejection/offer-rejection-component';
import { OFFER_ID_COPY, BENEFITS_COPY } from '../../redux/constants/ru-constants'
import { copyOfferDetailsEvent, initDunamis, offerAcceptOrRejectEvent } from '../../Analytics/vao-danalytics';
import { updateCTROfferAnalytics, updateCTRConsentAnalytics } from '../../Analytics/vao-ctr-analytics';
import { URLS, CardDetails } from '../../interfaces';
import Copy from '../../icons/Copy'
import themes from '../../themes'
import styles from './card-expand-view-styles.module.css'
import { useTheme } from '../../themes/ThemeContext'
import { updateOfferStatus } from '../../redux/actions/ru-actions';
import DOMPurify from 'dompurify';
import TurndownService from 'turndown'

interface CardComponentProps {
  customerId: string,
  queueName: string,
  channel: string,
  activeIndex: number,
  convId: string,
  urls: URLS,
  isVoiceContact: boolean,
  darkMode: boolean,
  isSalesContact: boolean,
  cardDetails: CardDetails,
  vaoWorkflowCategory: string,
  currentConvId: string,
  showCardExpandedView: boolean,
  callBackEvent: (event: any, eventData: object) => void,
}

const RuCardExpandViewComponent = (props: CardComponentProps) => {
  const {
    urls,
    convId,
    darkMode,
    cardDetails,
    activeIndex,
    customerId,
    callBackEvent,
    isVoiceContact,
    isSalesContact,
    vaoWorkflowCategory,
    showCardExpandedView,
  } = props;
  const { offerId = '', description = {}, descriptionHTML = {}, descriptionMarkDown = {}, offerStatus = '', offerText = '', title: campaignName = '', rejectReason = '' } = cardDetails || {};
  const { darkModeExpandView } = themes(darkMode);
  const [message, setMessage] = useState('');
  const offerName = offerText[0] || '';
  const timeoutRef = useRef(null); // To store the timeout ID
  const dispatch: any = useDispatch();
  const [showOfferMakeBox, setShowOfferMakeBox] = useState(false);
  const [showOfferConfirmBox, setShowOfferConfirmBox] = useState(false);
  const { RUInfo: {  multipartyAssignedAgent = false, convData = [] } = {} } = useSelector((state: any) => state)
  const textRef = useRef<HTMLDivElement>(null)
  
  const convDataKey = convId + customerId;
  const { cards, consentStatus } = convData?.[convDataKey] || {}

  const { theme } = useTheme()

  useEffect(() => {
    initDunamis();
    // Logic to show/hide the offer confirmation box
    shouldShowOfferConfirmBox();
    // Cleanup function to clear the timeout on unmount
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const allCampaignNames = cards?.map(card => card.title) || [];

    if (showCardExpandedView === true) {
      //CTR analytics
      updateCTROfferAnalytics(convId, allCampaignNames, isSalesContact, customerId);
    }
  }, [showCardExpandedView, customerId, offerStatus])

  useEffect(() => {
    const allCampaignNames = cards?.map(card => card.title) || []

    shouldShowOfferConfirmBox();

    // Just re-run the consent analytics to make sure the consent flags are updated, if component not re-render
    if (cards.length > 0) {
      updateCTRConsentAnalytics(consentStatus, allCampaignNames, isSalesContact);
    }

  }, [offerStatus, convId, customerId, isSalesContact, isVoiceContact, consentStatus]);

  /**
   * This function show or hide the offer make and confirm boxes based on the channel type, sales/support and offer status
   shouldShowOfferConfirmBox(false, false, undefined); // Support chat with undefined offer status
   shouldShowOfferConfirmBox(true, false, 'rejected');  // Sales chat with rejected offer status
   shouldShowOfferConfirmBox(false, true, 'undefined'); // Support telephony with undefined offer status
   shouldShowOfferConfirmBox(true, true, 'accepted');   // Sales telephony with accepted offer status
   */
  const shouldShowOfferConfirmBox = () => {
    let showMakeBox = false;
    let showConfirmBox = false;

    if (offerStatus === 'pitched') {
      showConfirmBox = true;
    } else if (offerStatus === 'rejected' || offerStatus === 'accepted') {
      showConfirmBox = true;
    } else if (offerStatus === '') {
      if (!isSalesContact && !isVoiceContact) {
        // do nothing
        showMakeBox = false;
        showConfirmBox = false;
      } else {
        showMakeBox = true;
      }
    }

    setShowOfferMakeBox(showMakeBox);
    setShowOfferConfirmBox(showConfirmBox);
  }

  const offerIdCopyHandler = () => {
    callBackEvent(OFFER_ID_COPY, { offer_id: offerId });
    navigator.clipboard.writeText(offerId).then(() => {
      setMessage('Copied!');
      // Clear any existing timeout before setting a new one
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => setMessage(''), 2000); // Clear message after 2 seconds
    });
    copyOfferDetailsEvent(vaoWorkflowCategory, offerId, offerName, campaignName, offerId)
  }

  const getSelectedMarkDownFromHtmlFragment = (selection) => {
    if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const htmlContent = range.cloneContents();
        const container = document.createElement('div');
        container.appendChild(htmlContent);
        // Check if the selected text belongs to a parent <a> tag
        const parentAnchor = range.startContainer.parentElement.closest('a');
        if (parentAnchor && range.commonAncestorContainer.nodeName === '#text') {
            // Manually create Markdown for the link
            const linkText = parentAnchor.textContent;
            const href = parentAnchor.getAttribute('href');
            const markDown = `[${linkText}](${href})`;
            return markDown;
        }
        var turndownService = new TurndownService()
        var markdown = turndownService.turndown(container.innerHTML)
        return markdown;
    }
    return '';
};

  const benefitsCopyHandler = () => {
    const selection = window.getSelection()
    const selectedText = selection?.toString()

    if (selectedText && textRef.current?.contains(selection?.anchorNode ?? null)) {
      const selectedMarkdowFragment = getSelectedMarkDownFromHtmlFragment(selection)
      if (selectedMarkdowFragment) {
        
        callBackEvent(BENEFITS_COPY, { message: { text: selectedMarkdowFragment } })
      }
    } else if (!selectedText) {
      callBackEvent(BENEFITS_COPY, { message: { text: descriptionMarkDown?.benefits?.points } });
      copyOfferDetailsEvent(vaoWorkflowCategory, offerId, offerName, campaignName, description?.benefits?.question);
      }
  };
  
  const handleOfferAcceptance = () => {
    dispatch(updateOfferStatus({ convId, customerId, index: activeIndex, offerId, offerStatus: 'accepted', rejectReason, url: urls.PERSONALIZATION_DOMAIN }));

    //analytics
    offerAcceptOrRejectEvent(vaoWorkflowCategory, 'yes', offerId, offerName, campaignName, 'accepted-offer');
  };

  const handleOfferRejection = () => {
    // dispatch(updateOfferStatus({ convId, customerId, index: activeIndex, offerId, offerStatus: 'rejected', rejectReason, url: urls.PERSONALIZATION_DOMAIN }));
    offerAcceptOrRejectEvent(vaoWorkflowCategory, 'no', offerId, offerName, campaignName, 'rejected-offer');
  };

  const handleMakeOffer = () => {
    dispatch(updateOfferStatus({ convId, customerId, index: activeIndex, offerId, offerStatus: 'pitched', rejectReason, url: urls.PERSONALIZATION_DOMAIN }));

    // analytics
    offerAcceptOrRejectEvent(vaoWorkflowCategory, 'yes', offerId, offerName, campaignName, 'did-you-make-the-offer');
  }

  const sanitizedHTML = (html = '') => {
    try{
      const sanitizedHTML= DOMPurify.sanitize(html) 
      const parser = new DOMParser();
      const doc = parser.parseFromString(sanitizedHTML, 'text/html');
      //mandatory set attribute targer='_blank' for all links
      doc.querySelectorAll('a').forEach(a => {
        a.setAttribute('target', '_blank');
      });
      return doc?.body?.innerHTML
    } catch (Error){
      console.log(`Error happened during sanitising html - ${html} from Odin, Error:`, Error)
      return ''
    }
  }

  return (
    <div className={`${styles.ru__card__expand__container}`}>
      <span className={darkMode ? `${styles.hr__line} ${styles.hr__line__dark}` : `${styles.hr__line}`}></span>
      <div style={{ background: theme.background, color: theme.color, borderColor: theme['border-color'] }} className={`${styles.ru__card__expand__view} ${darkModeExpandView} `}>
        <div className={`${styles.inner__container}`}>
          {isVoiceContact && (
              <div style={{background: theme['box2-color']}}className={styles.offer_box}>
                <Text UNSAFE_style={{ fontSize: '13px', fontWeight: 'bold' }}>Helpful offer information to relay to customer</Text>
                <Text UNSAFE_className={`${styles.ru_offer_relay_message}`}>
                { description.messageText.en }
                </Text>
              </div>
          )} 
          {
            showOfferMakeBox &&
            <OfferMakeConfirmComponent
              urls={urls}
              convId={convId}
              offerId={offerId}
              customerId={props.customerId}
              activeIndex={activeIndex}
              isSalesContact={isSalesContact}
              cardDetails={cardDetails}
              vaoWorkflowCategory={vaoWorkflowCategory}
              handleYes={handleMakeOffer}
              handleNo={handleOfferRejection}
              textContent={"Did you make the offer?"}
              showOfferMakeBox={showOfferMakeBox}
            />
          }
          {
            showOfferConfirmBox &&
            <OfferMakeConfirmComponent
              urls={urls}
              convId={convId}
              offerId={offerId}
              customerId={props.customerId}
              activeIndex={activeIndex}
              isSalesContact={isSalesContact}
              cardDetails={cardDetails}
              handleYes={handleOfferAcceptance}
              handleNo={handleOfferRejection}
              vaoWorkflowCategory={vaoWorkflowCategory}
              textContent={"Did the customer accept the offer?"}
            />
          }
          {
            offerStatus === 'rejected' &&
            <OfferRejectReasonComponent
              urls={urls}
              convId={convId}
              customerId={props.customerId}
              index={activeIndex}
              cardDetails={cardDetails}
              vaoWorkflowCategory={vaoWorkflowCategory}
            />
          }
          <div style={{ paddingBottom: '1.5em' }}>
            <div className={`${styles.copy__to__chat}`}>
              <Text UNSAFE_className={`${styles.offer__details__text}`}>OfferID</Text>
              <div className={`${styles.copy__icon}`}>
                <Link isHidden={!multipartyAssignedAgent} onPress={offerIdCopyHandler}>
                  <div>
                    <Copy fill={theme['copy-icon']} title='Copy to Clipboard' />
                  </div>
                </Link>
                {message && <span style={{ position: 'absolute', right: 0 }}>copied!</span>}
              </div>
            </div>
            <Text>{offerId}</Text>
          </div>
          <div style={{ paddingBottom: '1.5em' }}>
            <div className={`${styles.copy__to__chat}`}>
              <Text UNSAFE_className={`${styles.offer__details__text}`}>{description?.benefits?.question}</Text>
              {
                (!isVoiceContact) &&
                <div className={`${styles.copy__icon}`}>
                  <Link isHidden={!multipartyAssignedAgent} onPress={benefitsCopyHandler}>
                    <div>
                      <Copy fill={theme['copy-icon']} title='Copy to Chat' />
                    </div>
                  </Link>
                </div>
              }
            </div>
            <div className = {`${styles.inner_html} ${darkMode && styles.inner_html_dark}`} dangerouslySetInnerHTML={{ __html: sanitizedHTML(descriptionHTML?.benefits?.points) || "" }} ref={textRef}/>
          </div>
          <div>
            <Text UNSAFE_className={`${styles.offer__details__text}`}>{description?.eligibilityReasons?.question}</Text>
            <div className = {`${styles.inner_html} ${darkMode && styles.inner_html_dark}`} dangerouslySetInnerHTML={{ __html:  sanitizedHTML(descriptionHTML?.eligibilityReasons?.points) || ""}} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RuCardExpandViewComponent
