import getClient from '../utils/client';

export default async function validateCustomer(
  conversationId: string,
  rengaId: string,
): Promise<any> {
  return getClient(true)
    .post(`/api/v1/customer/validate`, {
      conversationId,
      rengaId,
    })
    .then(res => {
      const receivedRes = res.data ? res.data : {};
      console.log('oc-ccm-module, validateCustomerResponse :', JSON.stringify(receivedRes));
      return receivedRes;
    })
    .catch(e => {
      console.log('oc-ccm-module, validateCustomerResponse : Exception Occured', e);
      return {};
    });
}
