import { apiClient } from './axiosSettings'

export async function getConvHistory(customerId: string, endpoint: string, source: any, headers: any) {
  const customerIdEncoded = encodeURIComponent(customerId)
  return apiClient.get(`${endpoint}/api/histories/customer?customerId=${customerIdEncoded}`, { cancelToken: source.token, headers: headers })
}

export async function fetchTranscripts(conversationId: string, endpoint: string, source: any, headers: any) {

  return apiClient.get(`${endpoint}/api/transcripts/${conversationId}?mode=short`, { cancelToken: source.token, headers: headers })
}

export async function getTranslationData(reqData: any, source: any, headers: { Authorization: string; 'x-api-key': string; }) {
  return apiClient.post(`${reqData.endpoint}/api/translate/chat`, reqData.body, { cancelToken: source.token, headers: headers });
}

export interface ConversationHistory {
  conversationId: string,
  customerId: string,
  endTimestamp: string,
  lastAgentId: string,
  lastQueue: string,
  startTimeStamp: string
}
