import React from 'react';

export const ChevronRight = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18" width="18">
        <defs>
            <style>
                {`.ch-chevron-right-a {
                    fill: #6E6E6E;
                }`}
            </style>
        </defs>
        <rect id="Canvas" fill="#6E6E6E" opacity="0" width="18" height="18" /><path className="ch-chevron-right-a" d="M12,9a.994.994,0,0,1-.2925.7045l-3.9915,3.99a1,1,0,1,1-1.4355-1.386l.0245-.0245L9.5905,9,6.3045,5.715A1,1,0,0,1,7.691,4.28l.0245.0245,3.9915,3.99A.994.994,0,0,1,12,9Z" />
    </svg>
)

export const Refresh = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18" width="18">
        <defs>
            <style>
                {`.ch-refersh-a {
                    fill: #6E6E6E;
                }`}
            </style>
        </defs>
        <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" /><path className="ch-refersh-a" d="M16.337,10H15.39a.6075.6075,0,0,0-.581.469A5.7235,5.7235,0,0,1,5.25,13.006l-.346-.3465L6.8815,10.682A.392.392,0,0,0,7,10.4a.4.4,0,0,0-.377-.4H1.25a.25.25,0,0,0-.25.25v5.375A.4.4,0,0,0,1.4,16a.3905.3905,0,0,0,.28-.118l1.8085-1.8085.178.1785a8.09048,8.09048,0,0,0,3.642,2.1655,7.715,7.715,0,0,0,9.4379-5.47434q.04733-.178.0861-.35816A.5.5,0,0,0,16.337,10Z" />
        <path className="ch-refersh-a" d="M16.6,2a.3905.3905,0,0,0-.28.118L14.5095,3.9265l-.178-.1765a8.09048,8.09048,0,0,0-3.642-2.1655A7.715,7.715,0,0,0,1.25269,7.06072q-.04677.17612-.08519.35428A.5.5,0,0,0,1.663,8H2.61a.6075.6075,0,0,0,.581-.469A5.7235,5.7235,0,0,1,12.75,4.994l.346.3465L11.1185,7.318A.392.392,0,0,0,11,7.6a.4.4,0,0,0,.377.4H16.75A.25.25,0,0,0,17,7.75V2.377A.4.4,0,0,0,16.6,2Z" />
    </svg>
)

export const ClosedConvIcon = (props: any) => (
    <svg id="icon_-_ongoing" data-name="icon - ongoing" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <g id="Smock_Chat_18_N">
            <rect id="Canvas" width="18" height="18" fill={props.color} opacity="0" />
            <path id="Path_104" data-name="Path 104" d="M9.5,7a.5.5,0,0,1,.5.5v6a.5.5,0,0,1-.5.5H4.793a.5.5,0,0,0-.353.146L3,15.585V14.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,1-.5-.5v-6A.5.5,0,0,1,1.5,7Zm-8-1A1.5,1.5,0,0,0,0,7.5v6A1.5,1.5,0,0,0,1.5,15H2v2.4a.25.25,0,0,0,.427.177L5,15H9.5A1.5,1.5,0,0,0,11,13.5v-6A1.5,1.5,0,0,0,9.5,6Z" fill={props.color} />
            <path id="Path_105" data-name="Path 105" d="M12,7.3A2.3,2.3,0,0,0,9.7,5H6V2.5A1.5,1.5,0,0,1,7.5,1h9A1.5,1.5,0,0,1,18,2.5v6A1.5,1.5,0,0,1,16.5,10H15v2.4a.25.25,0,0,1-.427.177L12,10Z" fill={props.color} />
        </g>
    </svg>
);

export const CallIcon = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.508" height="14.807" viewBox="0 0 14.508 14.807">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_151009" data-name="Rectangle 151009" width="14.508" height="14.807" stroke={props.color} fill="none" />
            </clipPath>
        </defs>
        <g id="Group_161830" data-name="Group 161830" clipPath="url(#clip-path)">
            <g id="Group_162281" data-name="Group 162281">
                <path id="Path_84298" data-name="Path 84298" d="M201.417.419a.784.784,0,0,0,.133.879.912.912,0,0,0,.68.249,6.1,6.1,0,0,1,5.241,3.217,5.865,5.865,0,0,1,.763,2.886.75.75,0,0,0,.829.763.72.72,0,0,0,.73-.813,7.948,7.948,0,0,0-2.438-5.506c-.232-.182-.63-.531-1.061-.813A7.6,7.6,0,0,0,202.131,0a.641.641,0,0,0-.713.415Zm-.149,3.45a.739.739,0,0,0,.746.564,3.307,3.307,0,0,1,3.333,3.3.893.893,0,0,0,.166.5.79.79,0,0,0,.912.232.712.712,0,0,0,.464-.73,4.87,4.87,0,0,0-4.826-4.859,1.23,1.23,0,0,0-.415.083.807.807,0,0,0-.381.912Z" transform="translate(-195.285 0.002)" stroke={props.color} fill="none" />
                <path id="Path_84299" data-name="Path 84299" d="M3.925,72.361l.748-.748c.224-.224.037-.7-.185-.92L2.134,68.34c-.223-.223-.3-.017-.528.207l-.748.748Zm4.463,4.463.748-.748a.634.634,0,0,1,.849.114l2.358,2.358c.221.221.367.653.142.877l-.748.748ZM5.174,75.575c-2.159-2.145-1.833-2.629-1.6-2.86l.08-.08L.584,69.568l-.1.1c-.414.414-1.737,3.1,3.021,7.856s7.442,3.435,7.854,3.023l.1-.1L8.112,77.1l-.083.083c-.231.231-.7.543-2.861-1.6Z" transform="translate(0 -66.223)" stroke={props.color} fill="none" />
            </g>
        </g>
    </svg>
)