import axios from 'axios';
import store from '../Store/Reducers/index';
import { getAuthHeaders } from './getAuthHeaders';
let CancelToken: any = {};
let source: any = {};

export const createCancelToken = () => {
  console.log('CCM Module HOME - creating cancel token');
  CancelToken = axios.CancelToken;
  source = CancelToken.source();
};

export const deleteCancelToken = () => {
  console.log('CCM Module HOME - deleting API calls');
  source.cancel('API Calls cancelled on unmount');
};

function getClient(cancelRequest?: any) {
  const data = store.getState();
  const { REACT_APP_CCM_BASE_URL: baseURL } = data.ui.env;
  return axios.create({
    baseURL: baseURL,
    timeout: 30000,
    headers: getAuthHeaders(),
    ...(cancelRequest && { cancelToken: source.token }),
  });
}

export default getClient;
