import getClient from '../utils/client';

export default async function getCustomer(conversationId: string): Promise<any> {
  return getClient(true)
    .get(`/api/v1/db/fetch?conversationId=${conversationId}`)
    .then(res => {
      const receivedRes = res.data ? res.data : {};
      console.log('oc-ccm-module, getCasesResponse :', JSON.stringify(receivedRes));
      return receivedRes;
    })
    .catch(e => {
      if (e && e.message && e.message.includes('API Calls cancelled on unmount')) {
        console.log('oc-ccm-module, loadCustomerResponse - cancelled');
        return { cancelled: true };
      }
      console.log('oc-ccm-module, getCasesResponse : Exception Occured', e);
      return {};
    });
}
