import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TextArea } from '@adobe/react-spectrum';
import localization from '../language/localization';
import darkTheme from '../Theme/Styles/main/dark.css';
import lightTheme from '../Theme/Styles/main/light.css';

import { CheckMarkIconGreen, Chevron, CircleCrossRed } from '../Theme/Icons/icons';

import { IDBPDatabase, openDB } from 'idb';

export function ErrorAgentNotesFallback() {
  return (
    <div role="alert" style={{ padding: '0px 1.25rem 0px 1.25rem' }}>
      <p>Something went wrong in Agent Notes component</p>
      <pre>Please add Agent Notes manually in Dynamics</pre>
    </div>
  );
}

const AgentNotes = (props: any) => {
  const { agentNotesCollapsedHandle } = props;

  const darkMode = useSelector((state: any) => state?.ui?.darkMode);
  const styles = darkMode ? darkTheme : lightTheme;
  const language = useSelector((state: any) => state?.ui?.language);
  const { viewingAgentName = '', viewingAgentLDAP = '' } = useSelector((state: any) => state?.oc);
  const localizeString: any = localization[language];
  const {
    conversationId = '',
    currentContactId = '',
    isConversationStillOpen = false,
  } = useSelector((state: any) => state?.oc);
  const { activeCase = {} } = useSelector((state: any) => state?.case);
  const closedConversationsNotes =
    useSelector((state: any) => state?.notes?.notesClosedConversation?.[currentContactId]?.notes) ||
    {};
  const {
    agentNotes: stateNotes = '',
    agentName = '',
    updatedToMilo: updatedToMiloDB = false,
  } = closedConversationsNotes;
  const agentDisplayingName = agentName || viewingAgentName;
  const mexLength = 3000;
  const TTL = 12 * 60 * 60 * 1000; // 12 hours
  const [updatedToMilo, setUpdatedToMile] = useState(false)

  console.log(
    'agentDisplayingName, agentName, viewingAgentName, stateNotes',
    agentDisplayingName,
    agentName,
    viewingAgentName,
    stateNotes,
  );

  const contactListCollapsed: any = useSelector((state: any) => state?.notes?.contactList) || false;
  const agentNotesCollapsed =
    contactListCollapsed?.[currentContactId]?.agentNotesCollapsed || false;
  const updatedToMiloContext = useSelector((state: any) => state?.notes?.updatedToMilo) || false;
  const [db, setDb] = useState<IDBPDatabase | null>(null);
  const [notes, setNotes] = useState('');
  // Handle click and rotation
  const handleRotate = () => {
    if (agentNotesCollapsedHandle) {
      agentNotesCollapsedHandle(); // Call the passed function
    }
  };

  // Initialize IndexedDB and clean expired data
  useEffect(() => {
    const initAndCleanDB = async () => {
      const database = await openDB('AgentNotesDB', 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains('AgentNotes')) {
            db.createObjectStore('AgentNotes', { keyPath: 'contactId' });
          }
        },
      });
      setDb(database); // Set the database in state
      // Function to clean expired data

      const cleanExpiredData = async (db: IDBPDatabase) => {
        console.log('Running cleanExpiredData...');
        const tx = db.transaction('AgentNotes', 'readwrite');
        const store = tx.objectStore('AgentNotes');
        const now = Date.now();
        const allKeys = await store.getAllKeys();

        for (const key of allKeys) {
          const record = await store.get(key);
          if ((record?.ttl && record.ttl < now) || record?.ttl === undefined) {
            await store.delete(key);
          }
        }

        await tx.done;
      };

      await cleanExpiredData(database);
    };

    initAndCleanDB();
  }, []);

  useEffect(() => {
    console.log('useEffect db currentContactId', currentContactId, stateNotes);
    if (!db || !currentContactId) return;

    const fetchNotes = async () => {
      try {
        const storedContactRecords = await db.get('AgentNotes', currentContactId);
        const storedContactAgentNotes = storedContactRecords?.notes || '';
        setNotes(stateNotes || storedContactAgentNotes);
      } catch (error) {
        console.error('Error fetching notes:', error);
      }
    };

    fetchNotes();
  }, [db, currentContactId]);

  useEffect(() => {
    setNotes(stateNotes);
  }, [stateNotes]);

  useEffect(() => {
    saveNotes(db, currentContactId, conversationId, activeCase, viewingAgentLDAP, notes);
  }, [activeCase]);

  useEffect(() => {
    console.log(
      'useEffect isConversationStillOpen, updatedToMiloDB, updatedToMiloContext',
      isConversationStillOpen,
      updatedToMiloDB,
      updatedToMiloContext
    );
    if (!isConversationStillOpen) {
      setUpdatedToMile(updatedToMiloDB || updatedToMiloContext);
    } else {
      setUpdatedToMile(false);
    }
  }, [updatedToMiloDB, isConversationStillOpen, updatedToMiloContext]);

  // Handle text area changes
  const handleChange = (value: any) => {
    setNotes(value);
    saveNotesDebounce(value);
  };

  const saveNotes = async (
    db: IDBPDatabase | null,
    contactId: string = '',
    conversationId: string = '',
    activeCase: any = {},
    agentLDAP: string = '',
    notes: string = '',
  ) => {
    if (!contactId || !db) {
      return;
    }
    const ttl = Date.now() + TTL;
    const data = { contactId, notes, agentLDAP, activeCase, conversationId, ttl };
    try {
      await db.put('AgentNotes', data);
    } catch (error) {
      console.error('Error saving notes:', error);
    }
  };
  // Debounced save function
  const saveNotesDebounce = useCallback(
    debounce(
      (notes: string) =>
        saveNotes(db, currentContactId, conversationId, activeCase, viewingAgentLDAP, notes),
      500,
    ),
    [db, currentContactId],
  );

  // Debounce utility function
  function debounce<T extends (...args: any[]) => any>(
    func: T,
    wait: number,
  ): (...args: Parameters<T>) => void {
    let timeout: ReturnType<typeof setTimeout>; // Correctly type the timeout
    return function (...args: Parameters<T>) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  }

  const advisorNotesString = (localizeString = '', agentName = '') => {
    return localizeString.replace('XXX', agentName);
  };

  const displayC1UploadStatus = () => {
    return !isConversationStillOpen ? (
      updatedToMilo ? (
        <div className={styles['ccm_module_agent-notes-checkmark']}>
          <CheckMarkIconGreen />
        </div>
      ) : (
        <div className={styles['ccm_module_agent-notes-circle-cross']}>
          <CircleCrossRed />
        </div>
      )
    ) : (
      <React.Fragment></React.Fragment>
    );
  };

  return (
    <div
      className={styles['ccm_module_agent-notes-container']}
      style={{
        height: agentNotesCollapsed ? '36px' : '128px',
        transition: 'height 0.4s ease',
      }}>
      <div className={styles['ccm_module_agent-notes-header']} onClick={handleRotate}>
        <div className={styles['ccm_module_agent-notes-title']}>
          {displayC1UploadStatus()}
          {advisorNotesString(localizeString.agent_notes_advisor, agentDisplayingName)}
        </div>
        <div
          className={
            !agentNotesCollapsed ? styles['chevron-button-rotate'] : styles['chevron-button']
          }>
          <Chevron
            title={
              !agentNotesCollapsed ? localizeString.agent_notes_hide : localizeString.agent_notes_show
            }
          />
        </div>
      </div>

      <div
        className={
          !agentNotesCollapsed
            ? styles['ccm_module_agent-notes-body']
            : styles['ccm_module_agent-notes-body-hide']
        }
        style={{ transition: 'display 0.4s ease' }}>
        <TextArea
          labelPosition="top"
          label={localizeString.agent_notes_description}
          width="470px"
          maxLength={mexLength}
          height="size-50"
          maxHeight={55}
          isReadOnly={!isConversationStillOpen}
          onChange={handleChange}
          value={notes}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '4px' }}>
          <span style={{ fontSize: '10px', color: '#888' }}>
            {mexLength - notes.length} {localization[language].agent_notes_characters_left}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AgentNotes;
