const initialState: any = {
  isZendesk: false,
  conversationId: '',
  currentContactId: '',
  isConversationStillOpen: true,
  originalSkillName: '',
  currentSkillName: '',
  assignedAgentLDAP: '',
  viewingAgentLDAP: '',
  viewingAgentName: '',
  currentContextData: {},
  allowToLinkCaseForReturnCustomer: true,
  transferCount: '0',
  isConversationTransferred: false,
  allowedAgent: false,
  isMultipleAccountLinked: '',
  origin: '',
  linkedConversationId: [],
};

type actionType = {
  type: string;
  payload: any;
};

/* istanbul ignore next */
const ocReducer = (state: any = initialState, action: actionType = { type: '', payload: '' }) => {
  switch (action.type) {
    case 'SET_OC_DATA': {
      return {
        ...state,
        isZendesk: action.payload.isZendesk,
        conversationId: action.payload.conversationId,
        currentContactId: action.payload.currentContactId,
        isConversationStillOpen: action.payload.isConversationStillOpen,
        originalSkillName: action.payload.originalSkillName,
        currentSkillName: action.payload.currentSkillName,
        assignedAgentLDAP: action.payload.assignedAgentLDAP,
        viewingAgentLDAP: action.payload.viewingAgentLDAP,
        viewingAgentName: action.payload.viewingAgentName,
      };
    }
    case 'CLEAR_OC_DATA': {
      return initialState;
    }
    case 'SET_CURRENT_CONTEXT': {
      return {
        ...state,
        currentContextData: action.payload,
      };
    }
    case 'SET_CONVERSATION_STATUS': {
      return {
        ...state,
        isConversationStillOpen: action.payload,
      };
    }
    case 'SET_RETURN_CUSTOMER_FLAG': {
      return {
        ...state,
        allowToLinkCaseForReturnCustomer: action.payload,
      };
    }
    case 'SET_TRANSFER_COUNT': {
      return {
        ...state,
        transferCount: action.payload,
      };
    }
    case 'SET_TRANSFER_STATUS': {
      return {
        ...state,
        isConversationTransferred: action.payload,
      };
    }
    case 'SET_ALLOWED_AGENT_FLAG': {
      return {
        ...state,
        allowedAgent: action.payload,
      };
    }
    case 'SET_MULTIPLE_ACCOUNT_LINKED': {
      return {
        ...state,
        isMultipleAccountLinked: action.payload,
      };
    }
    case 'SET_ORIGIN': {
      return {
        ...state,
        origin: action.payload,
      };
    }
    case 'SET_LINKED_CONVERSATION_ID': {
      return {
        ...state,
        linkedConversationId: action.payload,
      };
    }
    default:
      return state;
  }
};

export default ocReducer;
