import getClient from '../utils/client';
type unlinkCaseAPIPayload = {
  conversationId: string;
};

export default async function unlinkCase(payload: unlinkCaseAPIPayload): Promise<any> {
  return getClient()
    .put(`/api/v1/cases/unlink`, payload)
    .then(res => {
      const receivedRes = res.data ? res.data : {};
      console.log('oc-ccm-module, unlinkCasesResponse :', JSON.stringify(receivedRes));
      return receivedRes;
    })
    .catch(e => {
      console.log('oc-ccm-module, unlinkCasesResponse : Exception Occured', e);
      return {};
    });
}
