import { ProgressCircle } from '@adobe/react-spectrum';
import React from 'react';
import styles from '../../Theme/Styles/styles.css';
import List from './List';

type Props = {
        items: any;
        darkMode: boolean; 
        language: string;
        loadMore: boolean;
};


const ListWrapper = ({items, darkMode, language, loadMore} : Props) => {
        let theme = darkMode
        let lang = language;
        const newItems = items.map((obj: any) => {
          return <List
                key={obj.key}
                item={obj.item}
                darkMode={theme}
                lang={lang}
                endpoint={obj.endpoint}
                genAIEndpoint={obj.genAIEndpoint}
                CUSTOM_PANEL_API_KEY={obj.CUSTOM_PANEL_API_KEY}
                customPanelAccessToken={obj.customPanelAccessToken}
                callBackEvent={obj.callbackFn} 
                token={obj.token}
                apiKey={obj.apiKey} />
        }); 
        return (
                <div className={styles["ch-list-wrapper"]}>
                     <div id="ch-list-partial-items"> { newItems }</div>
                     { loadMore && <ProgressCircle aria-label="Loading…" isIndeterminate size='M' left={130} top={5}/> }
                </div>
        );
};

export default ListWrapper;