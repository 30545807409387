import React, { useState, useEffect } from 'react';
import PredefinedContentBody from './PredefinedContentBody';
import { ProgressCircle } from '@adobe/react-spectrum';
import { getPdcList } from '../Api';
import styles from '../Theme/Styles/styles.module.css';
import languageMapper from './languageMapper.json';
import PredefinedContentHeader from './PredefinedContentHeader';
import withFavoriteAndCustomPdc from '../hoc/withFavoriteAndCustomPdc';
import { translatePDC } from '../helpers/pdc';
import { ErrorBoundary } from 'react-error-boundary';
const CustomPredefinedContentBody = withFavoriteAndCustomPdc(PredefinedContentBody)
interface IProps {
  contextData: any;
  callBackEvent: (eventType: string, eventData: object) => void;
}
function ErrorFallback() {
  return (
    <div role="alert" style={{ padding: '0px 1.25rem 0px 1.25rem' }}>
      <p>Something went wrong:</p>
      <pre>Please refresh the widget</pre>
    </div>
  );
}
const PredefinedContent = (props: IProps) => {
  const [pdcList, setPdcList] = useState([]);
  const [originalPDCList, setOriginalPDCList] = useState([]);
  const [pdcLoading, setPdcLoading] = useState(true);

  const { contextData, callBackEvent } = props;

  const { contextData: {
    darkMode = false,
    agentId = '',
    language = 'en',
    autoTranslation = {},
    pdcQueueName = '',
    pdcLanguage = '',
    pdcUrl = '',
    sourceLang = '',
    currentContactId = '' } } = props;

  const geofluencyEnabled = autoTranslation?.[currentContactId]?.translation || false;

  const darkTheme = darkMode;
  const pdcTheme = darkMode ? 'dark' : 'light';

  const fetchPdc = async (
    pdcLanguage,
    pdcQueueName,
    pdcUrl,
    geofluency = false,
    sourceLang,
    lang
  ) => {
    setPdcLoading(true);

    try {
      const response = await getPdcList(languageMapper[pdcLanguage], pdcQueueName, pdcUrl, lang);
      const { json: { Items = [] } = {} } = response.data;

      if (Items.length !== 0) {
        setPdcList(Items);
        setOriginalPDCList(Items);
      }
      if (geofluency) {
        try {
          const translationResponse = translatePDC(Items, sourceLang, lang);
          setPdcList(translationResponse)
        } catch (err) {
          console.error("OC-PDC-WIDGET - Error Occured while translating PDC", err);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setPdcLoading(false);
      setTimeout(() => {
        (window as any).enable_PDC = false;
      });
    }
  };

  const fetchPDCCallback = () => {
    sessionStorage.removeItem(`favorites-${agentId}`);
    sessionStorage.removeItem(`custom-pdc-${agentId}`);

    const geofluencyEnabled = autoTranslation?.[currentContactId]?.translation || false
    fetchPdc(pdcLanguage, pdcQueueName, pdcUrl, geofluencyEnabled, sourceLang, language);
  };

  useEffect(() => {
    fetchPdc(pdcLanguage, pdcQueueName, pdcUrl, geofluencyEnabled, sourceLang, language);
  }, [])

  useEffect(() => {
    if (originalPDCList.length !== 0) {
      if (!geofluencyEnabled) {
        setPdcList(originalPDCList)
      }
      else {
        const translationResponse = translatePDC(originalPDCList, sourceLang, language);
        setPdcList(translationResponse)
      }
    }
  }, [geofluencyEnabled])

  return (
    <div className={styles['pdc-container']}>
      <PredefinedContentHeader
        theme={darkTheme}
        pdcCallback={fetchPDCCallback}
        language={language}
      />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {pdcLoading ? (
          <div className={styles['pdc-loader']}>
            <ProgressCircle aria-label="Loading…" isIndeterminate />
          </div>
        ) : (
          <CustomPredefinedContentBody
            pdcList={pdcList}
            callBackEvent={callBackEvent}
            contextData={contextData}
            pdcTheme={pdcTheme}
          />
        )}
      </ErrorBoundary>
    </div>
  );
}

export default PredefinedContent;
