import { useEffect, useState } from 'react';
// import { CSSProperties } from 'react';
import { ActionButton, Content, Divider, Flex, Grid, Item, TabList, TabPanels, Tabs, Text, View } from '@adobe/react-spectrum';
import axios from 'axios';
import { findPhoneNumbersInText } from 'libphonenumber-js';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { v4 as uuidv4 } from 'uuid';
import { fetchTranscripts, getTranslationData } from '../../api/conversationHistoryAPI';
import { fetchSummary } from '../../api/conversationSummary';
import localization from '../../lang/localization';
import { CallIcon, ChevronRight, ClosedConvIcon } from '../../Theme/Icons/ConversationHistoryIcons';
import styles from '../../Theme/Styles/styles.css';
import { uniqueIdGenerate } from '../../util';
import ConversationSummary from './ConversationSummaryView';
import React from 'react';

type propsData = {
    item: any,
    darkMode: boolean
    lang: string
    endpoint: string
    token: string
    apiKey: string
    genAIEndpoint: string
    CUSTOM_PANEL_API_KEY: string
    customPanelAccessToken: string
    callBackEvent: (eventType: string, eventData: object) => void
}

let source: any = null;
let accordianExpanded = false;
let fetchDetailsApiCallInProgress = false;
const SUMMARY = 'summary'

function sanitizeContent(content: string): string {
    try {
        const hyperlinkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s)]+(?:\.[^\s)]+)*(\?.*?)?)\)/g;

        const combinedFilePathRegex = /([A-Za-z]:\\(?:[^<>:"|?*\[\]\\]+\\)*?\[[^\]]+\][^<>:"|?*\[\]\\]*?)|([\/](?:[^<>:"|?*\[\]]*\/)*?\[[^\]]+\][^<>:"|?*\[\]]*?)/g;
        const filePaths: string[] = [];
        const trustedUrls: string[] = [];

        const specialCharPlaceholders: string[] = [];

        // Replace hyperlinks with placeholders
        content = content.replace(hyperlinkRegex, (match: string, text: string, url: string): string => {
            const parsedUrl = new URL(url)
            if (parsedUrl.hostname.endsWith('adobe.com')){
                trustedUrls.push(`[${text}](${url})`);
                return `{{TRUSTED_URL_${trustedUrls.length - 1}}}`;
            }
            return match; // Return the original link for non-Adobe URLs
        });

        // First, handle special file paths
        content = content.replace(combinedFilePathRegex, (filePath: string): string => {
            filePaths.push(filePath);
            return `{{FILE_PATH_${filePaths.length - 1}}}`;
        });



        // Replace sequences of backslashes followed by special characters with placeholders
        content = content.replace(/(\\+)([\[\]()<>&!])/g, (_: string, backslashes: string, char: string): string => {
            specialCharPlaceholders.push(backslashes + char);
            return `{{SPECIAL_CHAR_${specialCharPlaceholders.length - 1}}}`;
        });


        // Apply HTML entity encoding for all characters except placeholders
        content = content
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/\[/g, '&#91;')
            .replace(/\]/g, '&#93;')
            .replace(/\(/g, '&#40;')
            .replace(/\)/g, '&#41;')
            .replace(/!/g, '&#33;');

        // Restore file paths from placeholders
        content = content.replace(/{{FILE_PATH_(\d+)}}/g, (_, index: string): string => {
            return filePaths[Number(index)];
        });

        // Restore trusted URLs from placeholders
        content = content.replace(/{{TRUSTED_URL_(\d+)}}/g, (_, index: string): string => {
            return trustedUrls[Number(index)];
        });
        // Restore sequences of backslashes and special characters from placeholders
        content = content.replace(/{{SPECIAL_CHAR_(\d+)}}/g, (_, index: string): string => {
            return specialCharPlaceholders[Number(index)] || '';
        });

        return content;
    } catch (e) {
        return content
    }
}


const List = (props: propsData) => {
    const { item, lang, darkMode, endpoint, token, apiKey, genAIEndpoint, CUSTOM_PANEL_API_KEY,
        customPanelAccessToken, callBackEvent } = props;
    const [historyData, setHistoryData] = useState<any>({});
    const [isOpen, setOpen] = useState<boolean>(false);
    const [summaryGenerated, setSummaryGenerated] = useState<boolean>(false);
    const [summaryData, setSummaryData] = useState<any>({}); // to store the summary data
    const [summaryView, setSummaryView] = useState<boolean>(false); // to show the summary view
    const [summaryResponseFromAPI, setSummaryResponseFromAPI] = useState<any>({}); // to store the summary response from API

    const headers = {
        'Authorization': token,
        'x-api-key': apiKey
    };
    const keys: string[] = ["content", "genericAttachments", "contentType"];
    const [isCustomerTranslateConv, setCustomerTranslateConv] = useState<boolean>(false);
    const regionalLanguages = ['pt-BR', 'zh-TW', 'zh-CN'];

    useEffect(() => {
        accordianExpanded = true;
        fetchDetailsApiCallInProgress = false;
        setOpen(false);
        let localLang = lang;
        if (regionalLanguages.includes(lang)) localLang = lang.split('-')[0];
        item.isCustomerTranslateBtn = (getCustomerLang(item?.lastQueue) && localLang !== getCustomerLang(item?.lastQueue) && item?.channel === 'CHAT') || false;
        item.transcripts = [];
        item.chatTranscripts = [];
        item.translationTranscript = [];
        setHistoryData(item);
        const cleanUp = () => {
            source && source.cancel();
            accordianExpanded = false;
            fetchDetailsApiCallInProgress = false;
        }
        return cleanUp;
    }, [item?.uniqueId]);

    const disconnectReason: any = {
        "TRANSFER": `${localization[lang].TRANSFERRED}`,
        "AGENT_DISCONNECT": `${localization[lang].CLOSED_BY}${localization[lang].AGENT}`,
        "CUSTOMER_DISCONNECT": `${localization[lang].CLOSED_BY}${localization[lang].CUSTOMER}`,
        "IDLE": `${localization[lang].AUTO_CLOSED}${localization[lang].IDLE}`,
        "OVERDUE": `${localization[lang].AUTO_CLOSED}${localization[lang].OVERDUE}`,
        "CONTACT_FLOW_DISCONNECT": `${localization[lang].CLOSED_BY}${localization[lang].SYSTEM}`,
        "THIRD_PARTY_DISCONNECT": `${localization[lang].CLOSED_BY}${localization[lang].AGENT}`,
        "TELECOM_PROBLEM": `${localization[lang].NETWORK_DISCONNECT}`,
        "VoiceTransferClose": `${localization[lang].CLOSED_BY}${localization[lang].SYSTEM}`
    };

    const getFastFollowFlags = () => {
        try {
            const localData: any = localStorage.getItem('fastFollowFlags');
            return JSON.parse(localData) || {}
        } catch (e) {
            console.log('fastFollowFlags fail', e)
            return {}
        }
    }

    const isJson = (str: string) => {
        try {
            const json = JSON.parse(str);
            if (!Object.keys(json).every(element => keys.includes(element))) return false
        } catch (e) {
            return false;
        }
        return true;
    }

    const phoneNumberTolink = (input: any) => {
        const phoneNumber = findPhoneNumbersInText(input);
        if (phoneNumber) {
            const number = phoneNumber[0]?.number?.number;
            input = input.toString().replace(number, `[${number}](${number})`)
            return input
        }
    }

    const linkOnClick = (e: any) => {
        e.preventDefault();
        const phoneInText = findPhoneNumbersInText(e.target.href);
        if (phoneInText.length > 0) {
            const phoneNumber = phoneInText[0].number.number;
            return props.callBackEvent("phone-in-transcript", { phoneNumber });
        }
        else {
            window.open(e.target.href, "_blank", "noreferrer")
        }
    }

    const LinkRenderer = (props: any) => {
        return (
            <a href={props.href} onClick={linkOnClick}>
                {props.children}
            </a>
        );
    }

    /**
     * 1. This function fetches both the transcripts and conversation summary data.
     * 2. It is invoked when the accordion is expanded or collapsed, which can lead to multiple calls.
     * 3. To prevent concurrent API calls, a flag (`fetchDetailsApiCallInProgress`) is used, and the lengths of the transcripts and summary data are checked. 
     * 4. Even though axios cancel token is used, which is not quickly cancelling the API call when we toggle the accordian in faster times. 
     *    In that case, the flag (`fetchDetailsApiCallInProgress`) is used to prevent multiple API calls.
     * 5. When the accordion is expanded (`accordionExpanded`), the `fetchTranscripts` and `fetchSummary` APIs are called, and the `historyData` state is updated.
     * 6. When the accordion is collapsed, the `historyData` state is cleared.
     * @param conversationId 
     * @returns 
     */
    const fetchDetails = async (conversationId: string) => {
        const { transcripts = [], summary = {}, channel } = historyData || {};
        const filteredtranscripts = [];
        const extractedSummary = {};
        const headers = {
            'Authorization': customPanelAccessToken,
            'x-api-key': CUSTOM_PANEL_API_KEY
        }

        source = axios.CancelToken.source();
        setOpen(!isOpen);

        // For voice contacts, there won't be transcripts
        if (fetchDetailsApiCallInProgress || channel !== 'CHAT' || transcripts?.length > 0 || Object.keys(summary).length > 0) return;

        try {
            fetchDetailsApiCallInProgress = true;
            const transcriptsResponse: any = await fetchTranscripts(conversationId, endpoint, source, headers);
            const summaryResponse: any = await fetchSummary(conversationId, genAIEndpoint, source, headers);

            if (transcriptsResponse?.data) {
                // filter messages and events containing IGNORE keyword
                if (Array.isArray(transcriptsResponse.data) && transcriptsResponse.data.length > 0) {
                    const filteredData = transcriptsResponse.data.filter((i: any) => {
                        return (
                            (i.Type === 'EVENT' && (!i.Content || !i.Content.includes('IGNORE_'))) ||
                            (i.Content && i.Type === 'MESSAGE' && !i.Content.includes('IGNORE_'))
                        );
                    });

                    filteredtranscripts.push(...filteredData);
                }
            }

            if (summaryResponse?.data) {
                const { final: isFinalSummary = false, Summary: summary = {}, TransferCategory: agentPath = "" } = summaryResponse?.data || {};

                setSummaryGenerated(isFinalSummary)
                setSummaryData({ ...summary, agentPath })
                setSummaryResponseFromAPI({ ...summary, agentPath })
            }

            if (accordianExpanded) {
                transcriptsResponse.transcripts = filteredtranscripts;
                setHistoryData({ ...historyData, transcripts: filteredtranscripts, chatTranscripts: filteredtranscripts, summary: extractedSummary })
                callBackEvent('TRANSCRIPT_API', { message: transcriptsResponse, uniqueId: historyData?.uniqueId })
            } else {
                setHistoryData({ ...historyData, transcripts: [], summary: {} });
                callBackEvent('TRANSCRIPT_API', { message: [] })
            }

            fetchDetailsApiCallInProgress = false;
        }
        catch (e) {
            fetchDetailsApiCallInProgress = false;
            setHistoryData({ ...historyData, transcripts: [], summary: {} });
            callBackEvent('TRANSCRIPT_API', { message: [] });
            console.log("CH: Error getting Summary", e);
        }
    }

    const getFormattedContent = (content: any, participantRole: string, translateMsg: string = '') => {
        try {
            if (findPhoneNumbersInText(content) && !isJson(content)) content = phoneNumberTolink(content)
            const obj = isJson(content) ? isNaN(content) ? JSON.parse(content) : content.trim() : content.trim()
            if (obj && typeof obj === 'object') {
                return (
                    <div>
                        {(obj.genericAttachments[0].title) && <View marginTop={13} width="242px" maxWidth="242px" borderWidth='thin' borderColor={(!darkMode && participantRole === 'CUSTOMER') || (participantRole !== 'CUSTOMER') ? 'gray-300' : 'dark'} borderRadius="medium" paddingX="15px" paddingY="8px"
                            backgroundColor={participantRole !== 'CUSTOMER' ? 'static-blue' : 'gray-50'}
                            UNSAFE_className={styles.text}>{obj.genericAttachments[0].title}</View>}
                        {obj.genericAttachments[0].buttons.map((button: any) => {
                            return (<div key={uniqueIdGenerate()}>
                                <ActionButton width="242px" max-width="242" UNSAFE_className={styles['bot-message-button']} alignSelf="end" isDisabled>{button.text}</ActionButton>
                            </div>)
                        })}
                    </div>
                )
            }
            if (typeof obj === "boolean") {
                return (
                    <div>
                        <View marginTop={13} UNSAFE_className={styles['ccp-chat-text']} borderWidth="thin" borderColor={(!darkMode && participantRole === 'CUSTOMER') || (participantRole !== 'CUSTOMER') ? 'transparent' : 'dark'} borderRadius="medium" paddingX="15px" paddingY="8px"
                            backgroundColor={participantRole !== 'CUSTOMER' ? 'static-blue' : 'gray-50'}>
                            {obj.toString()}
                        </View>
                    </div>
                )
            }
            return (
                <div>
                    <View marginTop={13} UNSAFE_className={styles['ccp-chat-text']} borderWidth="thin" borderColor={(!darkMode && participantRole === 'CUSTOMER') || (participantRole !== 'CUSTOMER') ? 'transparent' : 'dark'} borderRadius="medium" paddingX="15px" paddingY="8px"
                        backgroundColor={participantRole !== 'CUSTOMER' ? 'static-blue' : 'gray-50'}>
                        {
                            (getFastFollowFlags()?.ALLOWED_CONTENT_TYPE_JSON && participantRole === 'AGENT' && translateMsg) &&
                            <ReactMarkdown disallowedElements={['script', 'iframe', 'style']} unwrapDisallowed={true} components={{ a: LinkRenderer }} className={`${styles['agent-link']} ${styles['conv-hist-agent-pre-translate-msg']}`}>
                                {sanitizeContent(translateMsg)}
                            </ReactMarkdown>
                        }
                        <ReactMarkdown disallowedElements={['script', 'iframe', 'style']} unwrapDisallowed={true} components={{ a: LinkRenderer }} className={`${participantRole !== 'CUSTOMER' ? styles['agent-link'] : styles['customer-link']} ${styles['conv-hist-text-link']}`}>
                            {sanitizeContent(obj)}
                        </ReactMarkdown>
                    </View>
                </div>
            )
        } catch (e) {
            return getOriginalContent(content, participantRole, translateMsg)
        }
    }

    const getOriginalContent: any = (content: any, participantRole: string, translateMsg: string = '') => {
        let newContent: any = ""
        try {
            const obj = JSON.parse(content)
            newContent = obj.content ? obj.content : JSON.stringify(obj)

        } catch (error) {
            newContent = content
        }

        return (
            <div>
                <View marginTop={13} UNSAFE_className={styles.text} borderWidth="thin" borderColor={(!darkMode && participantRole === 'CUSTOMER') || (participantRole !== 'CUSTOMER') ? 'gray-300' : 'dark'} borderRadius="medium" paddingX="15px" paddingY="8px"
                    backgroundColor={participantRole !== 'CUSTOMER' ? 'static-blue' : 'gray-50'}>
                    {
                        (getFastFollowFlags()?.ALLOWED_CONTENT_TYPE_JSON && participantRole === 'AGENT' && translateMsg) &&
                        <ReactMarkdown disallowedElements={['script', 'iframe', 'style']} unwrapDisallowed={true} components={{ a: LinkRenderer }} className={`${styles['agent-link']} ${styles['conv-hist-agent-pre-translate-msg']}`}>
                            {sanitizeContent(translateMsg)}
                        </ReactMarkdown>
                    }
                    <ReactMarkdown disallowedElements={['script', 'iframe', 'style']} unwrapDisallowed={true} components={{ a: LinkRenderer }} className={`${participantRole !== 'CUSTOMER' ? styles['agent-link'] : styles['customer-link']} ${styles['conv-hist-text-link']}`}>
                        {sanitizeContent(newContent)}
                    </ReactMarkdown>
                </View>
            </div>
        )
    }

    const getDisplayName = (displayName: string) => {
        if (displayName === 'BOT') {
            return localization[lang].BOT
        }
        return displayName
    }

    const getSystemMessage = (key: any, name: string) => {
        switch (key) {
            case "application/vnd.amazonaws.connect.event.chat.ended":
                return localization[lang].CHAT_ENDED;
            case "application/vnd.amazonaws.connect.event.participant.joined":
                return `${name} ${localization[lang].PARTICIPANT_JOINED}`
            case "application/vnd.amazonaws.connect.event.participant.left":
                return `${name} ${localization[lang].PARTICIPANT_LEFT}`
            case "application/vnd.amazonaws.connect.event.transfer.succeeded":
                return localization[lang].TRANSFER_SUCCEEDED;
            case "application/vnd.amazonaws.connect.event.transfer.failed":
                return localization[lang].TRANSFER_FAILED;
            default: return ''
        }
    }

    const parseCustomSystemMessage = (customMessage: string, replaceStr: string) => {
        return customMessage.replace(replaceStr, '');
    }

    const getCustomerLang = (chatQueue: string = '') => {
        if (!chatQueue) return 'en';
        const allowQueueLang = ['-Ja', '-Ko', '-Fr', '-It', '-Es', '-Pt', '-De', '-Zh'];
        let customerLang = 'en'
        for (let i = 0; i < allowQueueLang.length; i++) {
            if (chatQueue.includes(allowQueueLang[i])) {
                customerLang = allowQueueLang[i]?.split('-')[1]?.toLowerCase() || '';
                break;
            }
        }
        return customerLang || 'en';
    }

    const getItemMessage = (item: any) => {
        let messageContent = item?.Content || '';
        let translateContent = '';

        /**
         * try catch block use because of the we cannot rely on the contentType to implement the logic
         * - item.ContentType 'text/plain' somte Content get json string object 
         * - in translationTranscript array we are storing Content as string not the object for contentType application/json
         */
        try {
            const jsonContent = JSON.parse(messageContent) || {};
            if (item.ContentType === 'application/json' && jsonContent?.message) {
                messageContent = jsonContent?.message || '';
                translateContent = jsonContent?.metadata?.preTranslationText || jsonContent?.message || '';
            }
        } catch (err) { }

        return {
            messageContent,
            translateContent
        }
    }

    const handleCustomerTranslationMsgs = async () => {
        try {
            if (isCustomerTranslateConv) {
                setCustomerTranslateConv(false);
                setHistoryData({ ...historyData, transcripts: historyData?.chatTranscripts })
                return;
            };
            source = axios.CancelToken.source();

            if (historyData?.translationTranscript?.length > 0) {
                setHistoryData({ ...historyData, transcripts: historyData?.translationTranscript })
                setCustomerTranslateConv(true)
                return;
            }

            const textsData: any = [];
            const translateArr = historyData.chatTranscripts || [];
            const doNotAllowTranslate = ['AGENT'];
            translateArr.forEach((element: any) => {
                let text = '';
                if (element?.ContentType === "application/json") {
                    const jsonContent = JSON.parse(element.Content) || {};
                    const messageText = jsonContent?.message || '';
                    const preTranslationText = jsonContent?.metadata?.preTranslationText || '';
                    text = preTranslationText || messageText || '';
                } else {
                    text = element.Content || 'NA'
                }

                const participantRole = element?.ParticipantRole || '';
                textsData.push({
                    id: element.Id,
                    text: doNotAllowTranslate.includes(participantRole) ? (element.Content || 'NA') : (text || 'NA'),
                    doNotTranslate: (doNotAllowTranslate.includes(participantRole) && element?.ContentType === "application/json")
                })
            });

            const reqData = {
                endpoint: endpoint,
                body: {
                    "sourceLanguage": getCustomerLang(historyData?.lastQueue),
                    "targetLanguage": lang,
                    "texts": textsData
                }
            }
            const resp: any = await getTranslationData(reqData, source, headers);
            const { data: { texts: result = [] } } = resp?.data || {};
            const upsertData: any = translateArr.map((item: any, index: any) => ({
                ...item,
                Content: result[index]?.text || ''
            }))
            setCustomerTranslateConv(true)
            setHistoryData({ ...historyData, transcripts: upsertData, translationTranscript: upsertData });
            callBackEvent('TRANSLATION_TRANSCRIPT_CUSTOMER_MSGS', { transcripts: upsertData, translationTranscript: upsertData, uniqueId: historyData.uniqueId })

        } catch (e) {
            setHistoryData({ ...historyData, transcripts: historyData.chatTranscripts })
            console.log('Something went wrong', e);
        }
    }


    const getTranscriptsView = (i: any) => {
        if (i.Type === "EVENT" || i.DisplayName === "SYSTEM_MESSAGE") {
            return (
                <Flex justifyContent="center" marginTop="5px" key={i.Id}>
                    <Text UNSAFE_className={`${styles['chat-system-message']} ${styles['chat-message-subtext-' + (darkMode ? 'dark' : 'light')]}`}>
                        {(i.Type === "EVENT") && getSystemMessage(i.ContentType, i.DisplayName)}
                        {(i.Type === "MESSAGE") && i.Content}
                    </Text>
                </Flex>
            )
        }
        if (i?.Content?.indexOf('CUSTOM_SYSTEM_MESSAGE_') >= 0) {
            return (
                <Flex justifyContent="center" marginTop="5px" key={i.Id}>
                    <Text UNSAFE_className={`${styles['chat-system-message']} ${styles['chat-message-subtext-' + (darkMode ? 'dark' : 'light')]}`}>
                        {parseCustomSystemMessage(i.Content, 'CUSTOM_SYSTEM_MESSAGE_')}
                    </Text>
                </Flex>
            )
        }
        return (
            <div key={i.Id} className={(i.ParticipantRole === 'AGENT' || i.ParticipantRole === 'SYSTEM') ? styles['agent-message'] : styles['conv-hist-cust-message']}>
                {getFormattedContent(getItemMessage(i).messageContent, i.ParticipantRole, getItemMessage(i).translateContent)}
                <Text UNSAFE_className={`${styles['text-small']} ${styles['message-subtext-' + (darkMode ? 'dark' : 'light')]}`}>
                    {getDisplayName(i.DisplayName)} <span className={styles['cont-mod-name-dt']}> | </span> {new Date(i.AbsoluteTime).toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' })}
                </Text>
            </div>
        )
    }

    const setSelectedTab = (key: any) => {
        if (key == SUMMARY) {
            setSummaryView(true)
        } else {
            setSummaryView(false)
        }
    }

    const handleSummaryTranslation = async () => {
        if (!isCustomerTranslateConv) {
            //translate main_issue and conversation_points
            const { main_issue = "", conversation_points = [], agentPath = "", links = "" } = summaryData;
            const textsData: any = [];
            const translationMap: any = []
            translationMap[uuidv4()] = {
                category: "main_issue",
                preTranslationText: main_issue,
                postTranslationText: "",
            }

            conversation_points.forEach((point: any) => {
                translationMap[uuidv4()] = {
                    category: "conversation_points",
                    preTranslationText: point,
                    postTranslationText: "",
                }
            })
            source = axios.CancelToken.source();
            Object.entries(translationMap).forEach(([key, value]: any) => {
                textsData.push({
                    id: key,
                    text: value.preTranslationText,
                });
            });
            const request = {
                endpoint: endpoint,
                body: {
                    "sourceLanguage": getCustomerLang(historyData?.lastQueue),
                    "targetLanguage": lang,
                    "texts": textsData
                }
            }
            const resp: any = await getTranslationData(request, source, headers);
            const { data: { texts: result = [] } } = resp?.data || {};

            result.forEach((element: any) => {
                const id = element.id;
                const text = element.text;
                translationMap[id].postTranslationText = text;
            })

            //Update main_issue and conversation_points
            const updatedSummaryData = {
                main_issue: Object.values(translationMap).filter((item: any) => item.category === "main_issue").map((item: any) => item.postTranslationText).join(' '),
                conversation_points: Object.values(translationMap).filter((item: any) => item.category === "conversation_points").map((item: any) => item.postTranslationText)
            }
            setSummaryData({ ...updatedSummaryData, agentPath, links })
            setCustomerTranslateConv(true)
        } else {
            setSummaryData(summaryResponseFromAPI)
            setCustomerTranslateConv(false)
        }
    }
    return (
        <div className={styles.tab} key={historyData.uniqueId}>
            {/* <div className='list-area' onClick={() => fetchDetails(historyData.conversationId)}> </div> */}
            <label htmlFor={historyData.conversationId} data-testid="transcipt-api" onClick={() => fetchDetails(historyData.conversationId)}>
                <Flex height={60} justifyContent='left' alignItems='center' direction='row' UNSAFE_className={`${styles['list-tap-view']} ${isOpen && styles['list-tap-view-click']}`}>
                    <ChevronRight />
                    <span className={styles.svgicon}>
                        {item.channel === "CHAT" ?
                            <ClosedConvIcon color={darkMode ? '#b3b3b3' : '#6e6e6e'} className={`${darkMode ? styles['ac-closed-staus-icon-dark'] : styles['ac-closed-staus-icon']}`} />
                            : <CallIcon color={darkMode ? '#b3b3b3' : '#6e6e6e'} className={`${darkMode ? styles['ac-closed-staus-icon-dark'] : styles['ac-closed-staus-icon']}`} />
                        }
                    </span>
                    <Flex marginStart={14} marginEnd={14} direction='column'>
                        <Text UNSAFE_className={styles['con-mod-dt-text']}>{moment(historyData.startTimestamp).format('MMM DD YYYY HH:mm')}</Text>
                        <Text UNSAFE_className={`${styles['text-small']} ${styles['ch-text-lastag']} ${styles['sub-heading-' + (darkMode ? 'dark' : 'light')]}`}>
                            {' '}
                            {historyData.lastAgentId}
                        </Text>
                    </Flex>
                    <Flex UNSAFE_className={styles['con-his-mod-csat-text']}>
                        <Text UNSAFE_className={`${styles['sub-heading-' + (darkMode ? 'dark' : 'light')]}`}>
                            {localization[lang].CSAT}: {historyData.csat ? historyData.csat + '%' : '---'}
                        </Text>
                    </Flex>
                </Flex>
            </label>
            {isOpen && <div className={`${styles['tab-content']} ${styles['conv-hist-tab-content']} ${(isOpen) && styles['tab-content-open']}`}>
                <Content>
                    <Tabs aria-label='Converstion History' disabledKeys={historyData.channel === "VOICE" ? ["transcript"] : []} onSelectionChange={setSelectedTab}>
                        <TabList UNSAFE_className={styles['con-mod-tabs']}>
                            <Item key='transcript'>{localization[lang].TRNSCRIPT}</Item>
                            <Item key='ci'>{localization[lang].CONVERSTION_INFORMATION}</Item>
                            {summaryGenerated ? <Item key={SUMMARY}>{localization[lang].SUMMARY}</Item> : <Item>{''}</Item>}
                        </TabList>
                        <TabPanels UNSAFE_className={styles.content}>
                            <Item key='transcript' >
                                <PerfectScrollbar options={{ suppressScrollX: true }} style={{ position: 'relative', height: '300px', padding: '0px 10px' }} className={`${darkMode && 'oc-con-dark-custom-scrollbar'}`}>
                                    <div>
                                        {
                                            historyData?.isCustomerTranslateBtn &&
                                            <ActionButton UNSAFE_className={`${styles['conv-hist-translation-btn']} ${darkMode && styles['conv-hist-translation-btn-dark']} ${isCustomerTranslateConv && styles['conv-hist-translation-btn-disable']}`} onPress={handleCustomerTranslationMsgs}>
                                                <Text>{localization[lang].TRANSLATE_CONVERSATION}</Text>
                                            </ActionButton>
                                        }
                                        {historyData?.transcripts?.length > 0 && historyData.transcripts.map((i: any) => (
                                            getTranscriptsView(i)
                                        ))}
                                    </div>
                                </PerfectScrollbar>
                            </Item>
                            <Item key='ci'>
                                <Grid
                                    areas={['key  value']}
                                    columns={['100px', '138px']}
                                    rows={['34px', '34px', '34px', '34px']}
                                    rowGap='6px'
                                    columnGap='14px'
                                    marginTop='24px'
                                    UNSAFE_className={`${styles['text-small']}`}
                                >
                                    <Text UNSAFE_className={styles['ci-title-text']}>{localization[lang].TIME}</Text>
                                    <Text UNSAFE_className={styles['ci-val-text']}>
                                        {moment(historyData.startTimestamp).format('MMM DD YYYY hh:mma')}
                                        <br />
                                        {moment(historyData.endTimestamp).format('MMM DD YYYY hh:mma')}
                                    </Text>
                                    <Text UNSAFE_className={styles['ci-title-text']}>{localization[lang].CONVERSTION_ID}</Text>
                                    <Text UNSAFE_className={styles['ci-val-text']}>{historyData.conversationId}</Text>
                                    <Text UNSAFE_className={styles['ci-title-text']}>{localization[lang].SKILL}</Text>
                                    <Text UNSAFE_className={styles['ci-val-text']}>{historyData.lastQueue}</Text>
                                    <Text UNSAFE_className={styles['ci-title-text']}>{localization[lang].LATEST_AGENT_LDAP}</Text>
                                    <Text UNSAFE_className={styles['ci-val-text']}>{historyData.lastAgentId}</Text>
                                </Grid>
                            </Item>
                        </TabPanels>
                    </Tabs>
                    {(historyData?.transcripts?.length > 0 || historyData?.disconnectReason) && !summaryView &&
                        <Flex height={30} marginTop={26} marginBottom={21} justifyContent='center' UNSAFE_className={styles['tab-footer']}>
                            <Text UNSAFE_className={`${styles['text-small']} ${styles['message-subtext-' + (darkMode ? 'dark' : 'light')]} ${styles['italic-text']}`}>
                                {historyData.disconnectReason && (`${disconnectReason[historyData.disconnectReason] ? disconnectReason[historyData.disconnectReason] : historyData.disconnectReason}`)}
                            </Text>
                        </Flex>
                    }
                    {summaryView && <div className={styles['conv-hist-summary-main-div']}>
                        <PerfectScrollbar options={{ suppressScrollX: true }} style={{ position: 'relative', height: '345px', padding: '0px 10px' }} className={`${darkMode && 'oc-con-dark-custom-scrollbar'}`}>
                            <div>
                                {
                                    historyData?.isCustomerTranslateBtn &&
                                    <ActionButton UNSAFE_className={`${styles['conv-hist-translation-btn']} ${darkMode && styles['conv-hist-translation-btn-dark']} ${isCustomerTranslateConv && styles['conv-hist-translation-btn-disable']}`} onPress={handleSummaryTranslation}>
                                        <Text>{localization[lang].TRANSLATE_CONVERSATION}</Text>
                                    </ActionButton>
                                }
                                <ConversationSummary data={summaryData} darkMode={darkMode} language={lang} />
                            </div>
                        </PerfectScrollbar>
                    </div>}
                </Content>
            </div>}
            <Divider size='S' />
        </div>
    )
}

export default List;

