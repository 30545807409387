import { combineReducers, applyMiddleware, createStore } from 'redux';
import caseReducer from './caseReducer';
import uiReducer from './uiReducer';
import ocReducer from './ocReducer';
import notesReducer from './notesReducer';
import customerReducer from './customerReducer';
import thunk from 'redux-thunk';

const reducers = combineReducers({
  case: caseReducer,
  ui: uiReducer,
  customer: customerReducer,
  oc: ocReducer,
  notes: notesReducer,
});

const store = createStore(reducers, applyMiddleware(thunk));

export default store;
