export type AgentNotesCollapsedState = {
  currentContactId: string;
  agentNotesCollapsed?: boolean;
  agentNotes?: string;
};

export const setEligibilityCaseNotesFlag = (payload: boolean) => {
  console.log('Triggered setEligibilityCaseNotesFlag with Payload', payload);
  return { type: 'SET_ELIGIBILITY_CASE_NOTES_FLAG', payload };
};

export const updateAgentNotesCollapsedState = (payload: AgentNotesCollapsedState) => {
  console.log('Triggered updateAgentNotesCollapsedState with Payload', payload);
  return { type: 'UPDATE_AGENT_NOTES_Collapsed_STATE', payload };
};

export const setAgentNotes = (payload: AgentNotesCollapsedState) => {
  console.log('Triggered setAgentNotes with Payload', payload);
  return { type: 'SET_AGENT_NOTES_CLOSED_CONVERSATION', payload };
};

export const setUploadToMilo = (payload: boolean) => {
  console.log('Triggered setUploadToMilo with Payload', payload);
  return { type: 'SET_UPDATED_TO_MILO', payload };
};
