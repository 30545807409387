import React, { useRef, useState } from "react";
import styles from "../Theme/Styles/dialpad.styles.css"
import { findPhoneNumbersInText, getCountryCallingCode, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js/max'
import {
    View,
    Grid,
    repeat,
    ActionButton,
    Flex,
    Divider,
    lightTheme,
    Picker,
    Item,
    TooltipTrigger,
    Tooltip,
    Provider,
    darkTheme
} from "@adobe/react-spectrum"
import localization from "../lang/localization";
import { CallIcon, ArrowDown, Alert } from '../Theme/Icons/DialpadIcons';
import DialpadButtons from "./DialpadButtons";
// eslint-disable-next-line no-unused-vars
import { iDialpadProp, iPhoneData, iHeaders, iQuickConnectConfiguration } from '../api/Models';
import TransferCall from "./TransferCall";
const countries = require("i18n-iso-countries");
const touchtone = require('touchtone');
var baudio = require('webaudio');

type propsData = {
    dialpadProps: iDialpadProp
    phoneData: iPhoneData
    headers: iHeaders
    endPoint: string
    close: any
    transferTo: any
    currentConversation: any
    rtq: boolean
    returnType: string
    quickConnectConfiguration: iQuickConnectConfiguration
    allActiveTransferData: any
    closePopup: (close: any, clear: boolean, transferType: string) => void
    savePrevNumber: (contact: any, code: any, queue: any) => any
    transferFun: (data: any, close: any, transferToOverrideSuggestion: boolean) => void
    saveTransferSelections: (data: any, close: any, transferType: string) => void
}

const dialpadContent = [
    { number: '1', text: "" }, { number: '2', text: "ABC" }, { number: '3', text: "DEF" },
    { number: '4', text: "GHI" }, { number: '5', text: "JKL" }, { number: '6', text: "MNO" }, { number: '7', text: "PQRS" },
    { number: '8', text: "TUV" }, { number: '9', text: "WXYZ" }, { number: '*', text: "" }, { number: '0', text: "+" },
    { number: '#', text: "" }
];

const DialpadComponent = React.memo((props: propsData) => {
    const { close, phoneData, dialpadProps, savePrevNumber, closePopup, headers, transferTo, currentConversation, endPoint, rtq, returnType, transferFun, saveTransferSelections, quickConnectConfiguration, allActiveTransferData } = props;
    const { lang, darkMode, outBoundCall, outboundQueues, callStatus, chatStatus, isHold, isMute, isJoin, isSwap, isTransfer, currentContactId, callBackEvent, cipPhone, dilableCountries, callConnectionCount = 0, channelType } = dialpadProps;
    const [code, setCode] = useState<string>(phoneData?.code || localStorage.getItem('countryCode') || "");
    const [contact, setContact] = useState<string>(phoneData?.phone || cipPhone || "");
    const [queueARN, setQueueARN] = useState<React.Key>("");
    const [queue, setQueue] = useState<string>(phoneData?.queue || "");
    const queueList = outboundQueues || [];
    const [isTransferVoice, setTransferVoice] = useState<boolean>(false)
    const [transferVoiceData, setTransferVoiceData] = useState<any>('')
    const tone = touchtone({
        duration: 0.25,
        volume: 0.25
    });
    const inputRef = useRef<any>()

    const clickDialpadButton = (e: any) => {
        const num = e?.target?.id || '';
        const el = inputRef?.current;
        let start = el?.selectionStart;
        let end   = el?.selectionEnd;
        el.value = (contact?.substring(0, start) + num + contact?.substring(end, contact?.length));
        const newContact = el?.value
        el.focus();
        start = end = start + num?.length;
        el.setSelectionRange(start, end);
        tone.dial(num)
        baudio(tone.play()).play();
        setContact(newContact)
        savePrevNumber(newContact, code, queueARN);
        if (callStatus === "Active") callBackEvent("DTMF", { input: num })
    }

    const onCall = () => {
        const countryCode = code ? languageDropdown()?.find(item => item.country === code).code : "";
        const address = countryCode + contact;
        const country = findPhoneNumbersInText(address)[0]?.number?.country;
        const origin = (outBoundCall && ((!channelType || channelType !== 'messaging') || (channelType === 'messaging' &&  chatStatus === 'Closed')))? "outbound-phone" : "chat-to-phone"
        const queueName = queueList?.find((item: any) => item.queueARN === queueARN)?.name || "";
        const data = {
            origin: origin,
            status: "Active",
            countryCode: country,
            customerEndpoint: address,
            queueARN: queueARN,
            queueName: queueName
        }
        if (isValidPhone()) callBackEvent(origin, data);
        setContact("");
        setCode("");
    }

    const handleclose = () => {
        if (!callStatus || callStatus !== "Active") savePrevNumber("", "", "");
        closePopup(close, false, 'voice')
        callBackEvent("empty-cipNumber", {});
    }

    const handleInput = (e: any) => {
        const msg = e.target.value || '';
        const pattern = /^[0-9*#]*$/;
        if (msg.match(pattern)) {
            if (msg.length > contact.length) {
                tone.dial(msg.slice(-1));
                baudio(tone.play()).play();
                if (callStatus === "Active") callBackEvent("DTMF", { input: msg.slice(-1) });
            }
            setContact(msg)
            savePrevNumber(msg, code, queueARN);
        }
    }

    const getPhone = (phoneNumber: any) => {
        if (phoneNumber) {
            const dilableLang = lang?.split("-")[0];
            const code = countries?.getName(phoneNumber?.country, dilableLang);
            const contact = phoneNumber?.nationalNumber;
            setContact(contact)
            if (code) {
                handleCountryCodeSelection(code);
            }
            savePrevNumber(contact, code, queueARN);
        }
    }

    const setDigits = (pastedText: any) => {
        const contact = pastedText.replace(/\D/g, '');
        setContact(contact)
        savePrevNumber(contact, code, queueARN);
    }

    const handlePaste = (e: any) => {
        e.preventDefault();
        const getCodeVal = code ? languageDropdown().find(item => item.country === code)?.code : '';
        try {
            const pastedText = e.clipboardData?.getData('text');
            if (isValidPhoneNumber(pastedText)) {
                getPhone(parsePhoneNumber(pastedText))
            } else if (getCodeVal && isValidPhoneNumber(`${getCodeVal}${pastedText}`)) {
                getPhone(parsePhoneNumber(`${getCodeVal}${pastedText}`))
            } else if (isValidPhoneNumber(`+${pastedText}`)) {
                const phoneNumber = parsePhoneNumber(`+${pastedText}`)
                const country = phoneNumber?.country?.toLowerCase();
                const isAvailable = dilableCountries.find(i => i === country)
                if (isAvailable) getPhone(phoneNumber)
                else setDigits(pastedText)
            } else setDigits(pastedText)
        } catch (error) {
            console.log(error);
        }
    }

    const handleCountryCodeSelection = (selected: any) => {
        setCode(selected)
        savePrevNumber(contact, selected, queueARN);
        localStorage.setItem('countryCode', selected);
    }

    const handleDialpadCb = (eventType: string, eventData: any) => {
        if (eventType === 'DIALOG_CLICK') {
            handleDialpadButton(eventType, eventData);
            return;
        }
        if (eventType === "JOIN_CALL" && transferVoiceData) {
            eventData = transferVoiceData || ''
        }
        if (eventType === 'COLD_TRANSFER_CALL' || eventType === 'WARM_TRANSFER_CALL') {
            setTransferVoiceData(eventData);
            handleDialpadButton(eventType, eventData);
        }
        if (eventType === 'TRANSFER') {
            setTransferVoice(eventData?.transfer || false)
        }
        dialpadProps.callBackEvent(eventType, eventData);
    }

    const handleDialpadButton = (eventType?: string, eventData?: any) => {
        const queueNames = ["Secure IVR", "Voicemail Message", "SLS-Agent-Transfer"]
        const isGoToDialpadNumber = ((queueNames.includes(eventData?.queue?.name)) || (eventType === 'COLD_TRANSFER_CALL') || eventType === 'DIALOG_CLICK');
        if (isTransferVoice && isGoToDialpadNumber) {
            setTransferVoice(!isTransferVoice);
        }
    }

    const handleQueueSelection = (selected: any) => {
        setQueueARN(selected);
        const queueName = queueList?.find((item: any) => item.queueARN === selected || queueARN)?.name || "";
        setQueue(queueName);
        savePrevNumber(contact, code, selected);
    }

    const isValidPhone = () => {
        if (!code) return false;
        try {
            const countryCode = languageDropdown()?.find(item => item.country === code)?.code || "";
            const phoneNumber = countryCode + contact;
            if (!countryCode || !contact) return false;
            const parseNo = parsePhoneNumber(phoneNumber);
            if (parseNo?.isValid() && phoneNumber !== parseNo?.number) return false;
            return parseNo?.isValid() || false;
        } catch (e) {
            return false
        }
    }

    const languageDropdown = () => {
        const countriesArray: any[] = [];
        dilableCountries.forEach((country: any) => {
            const countrieObject = { country: '', code: '' };
            const dilableLang = lang.split("-")[0];
            countries.registerLocale(require(`i18n-iso-countries/langs/${dilableLang}.json`));
            const countryName = countries.getName(country, dilableLang)
            countrieObject.country = countryName;
            const countryString = country.toString().toUpperCase()
            countrieObject.code = "+" + getCountryCallingCode(countryString.toString())
            countriesArray.push(countrieObject)
        });
        countriesArray.sort((a, b) => ('' + a.country).localeCompare(b.country));
        return countriesArray;
    }

    return (
        <Provider theme={darkMode ? darkTheme: lightTheme} colorScheme="light">
        <View UNSAFE_className={`${darkMode ? styles['dialpad-popover-dark-background'] : styles['dialpad-popover-light-background']}`} >
                <Flex direction="column" justifyContent={"center"} alignItems={"center"} gap={"size-100"}>
                <ActionButton UNSAFE_className={styles['chev-down']}
                    onPress={handleclose} isQuiet>
                    <ArrowDown />
                </ActionButton>
                <DialpadButtons
                    lang={lang}
                    callBackEvent={handleDialpadCb}
                    closePopup={closePopup}
                    close={close}
                    callStatus={callStatus}
                    isHold={isHold}
                    isMute={isMute}
                    isJoin={isJoin}
                    isSwap={isSwap}
                    isTransfer={isTransfer}
                    currentContactId={currentContactId}
                    callConnectionCount={callConnectionCount}
                />
                <Divider start="size-0" width="406px" size="S" />
                {
                    !isTransferVoice ?
                        <Flex direction="column">
                            <Flex direction='row' marginTop='size-200' gap="size-125" justifyContent={"center"}>
                                <Picker label={localization[lang].TELEPHONY.COUNTRY_CODE} placeholder={localization[lang].TELEPHONY.SELECT} width="120px"
                                    menuWidth="size-3000" minWidth="110px" key={code}
                                    defaultSelectedKey={code} items={languageDropdown()}
                                    onSelectionChange={handleCountryCodeSelection}
                                    >
                                    {item => <Item key={item.country} textValue={close ? "" : code}>{`${item.country} (${item.code})`}</Item>}
                                </Picker>
                                <div className={styles['phone-input']}>
                                    <Flex direction={"column"} alignItems={"start"}>
                                        <label>{localization[lang].TELEPHONY.NUMBER}</label>
                                        <div>
                                        <input aria-label="Number" className={styles['phone-input-text']}
                                            placeholder={localization[lang].TELEPHONY.TYPE_NUMBER}
                                            value={contact} onChange={handleInput} onPaste={handlePaste}
                                            ref={inputRef}
                                        />
                                         {
                                        (!isValidPhone() && contact.length > 0) &&
                                        <TooltipTrigger>
                                            <ActionButton UNSAFE_className={styles['alert-toolbar-icon']}><Alert /></ActionButton>
                                            <Tooltip UNSAFE_className={styles['invalid-phone-tooltip']}>{localization[lang].TELEPHONY.INVALID_PHONE_TOOLTIP}</Tooltip>
                                        </TooltipTrigger>
                                    }
                                    </div>
                                    </Flex>   
                                </div>
                            </Flex>
                            <Grid marginTop="12px" columns={repeat(3, '48px')} justifyContent="center" columnGap="22px">
                                {dialpadContent?.map((x, index) => (
                                    <ActionButton key={index} UNSAFE_className={styles['dialpad-button']} width="60px" height="48px" isQuiet
                                        id={x.number} onPress={clickDialpadButton}>
                                        <Flex direction="column">
                                            <span>{x.number}</span>
                                            <sub className={styles['dialpad-letters']} style={{ marginBottom: x.text ? "none" : "8px" }}>{x.text}</sub>
                                        </Flex>
                                    </ActionButton>
                                ))}
                            </Grid>
                            {
                                outBoundCall && !chatStatus || chatStatus === 'Closed' ?
                                    <Picker label={localization[lang].TRANSFER_CALL.QUEUE} placeholder="Select.." width="190px" defaultSelectedKey={queue}
                                        menuWidth="size-2000" labelPosition="side" alignSelf="center" isDisabled={callStatus === "Active"}
                                        items={queueList} onSelectionChange={handleQueueSelection}>
                                        {(item: any) => <Item key={item.queueARN}>{item?.name}</Item>}
                                    </Picker> : null
                            }
                            <TooltipTrigger>
                                <ActionButton UNSAFE_className={styles['call-button']} alignSelf="center" onPress={onCall} isQuiet isDisabled={!isValidPhone()}><CallIcon />{localization[lang].TELEPHONY.CALL}</ActionButton>
                                <Tooltip>{localization[lang].TELEPHONY.CALL}</Tooltip>
                            </TooltipTrigger>
                        </Flex>
                        :
                        <TransferCall
                            darkMode={darkMode}
                            lang={lang}
                            endpoint={endPoint}
                            headers={headers}
                            transferQueueData={transferTo}
                            currentConversation={currentConversation}
                            rtq={rtq}
                            close={close}
                            returnType={returnType}
                            isDialpadTransfer
                            quickConnectConfiguration={quickConnectConfiguration}
                            transferFun={transferFun}
                            isTransfer={isTransfer}
                            saveTransferSelections={saveTransferSelections}
                            allActiveTransferData={allActiveTransferData}
                            closePopup={closePopup}
                            callBackEvent={handleDialpadCb}
                            routingProfileQueues={queueList}
                        />
                }
                </Flex>
        </View>
     </Provider>

    )
})

export default DialpadComponent;
