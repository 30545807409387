import React, { useEffect } from 'react'
import { Text, View, Flex, ActionButton } from '@adobe/react-spectrum' // , ProgressCircle
import styles from '../Theme/Styles/styles.css'
import { LockClosed, Attach, Download } from '../Theme/Icons';
import localization from '../lang/localization'
import ReactMarkdown from 'react-markdown'
import { findPhoneNumbersInText } from 'libphonenumber-js'

function sanitizeContent(content: string): string {
    try {
        const hyperlinkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s)]+(?:\.[^\s)]+)*(\?.*?)?)\)/g;

        const combinedFilePathRegex = /([A-Za-z]:\\(?:[^<>:"|?*\[\]\\]+\\)*?\[[^\]]+\][^<>:"|?*\[\]\\]*?)|([\/](?:[^<>:"|?*\[\]]*\/)*?\[[^\]]+\][^<>:"|?*\[\]]*?)/g;
        const filePaths: string[] = [];
        const trustedUrls: string[] = [];

        const specialCharPlaceholders: string[] = [];

        // Replace hyperlinks with placeholders
        content = content.replace(hyperlinkRegex, (match: string, text: string, url: string): string => {
            const parsedUrl = new URL(url)
            if (parsedUrl.hostname.endsWith('adobe.com')){
                trustedUrls.push(`[${text}](${url})`);
                return `{{TRUSTED_URL_${trustedUrls.length - 1}}}`;
            }
            return match; // Return the original link for non-Adobe URLs
        });

        // First, handle special file paths
        content = content.replace(combinedFilePathRegex, (filePath: string): string => {
            filePaths.push(filePath);
            return `{{FILE_PATH_${filePaths.length - 1}}}`;
        });



        // Replace sequences of backslashes followed by special characters with placeholders
        content = content.replace(/(\\+)([\[\]()<>&!])/g, (_: string, backslashes: string, char: string): string => {
            specialCharPlaceholders.push(backslashes + char);
            return `{{SPECIAL_CHAR_${specialCharPlaceholders.length - 1}}}`;
        });


        // Apply HTML entity encoding for all characters except placeholders
        content = content
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/\[/g, '&#91;')
            .replace(/\]/g, '&#93;')
            .replace(/\(/g, '&#40;')
            .replace(/\)/g, '&#41;')
            .replace(/!/g, '&#33;');

        // Restore file paths from placeholders
        content = content.replace(/{{FILE_PATH_(\d+)}}/g, (_, index: string): string => {
            return filePaths[Number(index)];
        });

        // Restore trusted URLs from placeholders
        content = content.replace(/{{TRUSTED_URL_(\d+)}}/g, (_, index: string): string => {
            return trustedUrls[Number(index)];
        });
        // Restore sequences of backslashes and special characters from placeholders
        content = content.replace(/{{SPECIAL_CHAR_(\d+)}}/g, (_, index: string): string => {
            return specialCharPlaceholders[Number(index)] || '';
        });

        return content;
    } catch (e) {
        return content
    }
}




type propsData = {
    key: string
    item: any
    darkMode: boolean
    lang: string
    chatStatus: string
    transferNoteFound: boolean
    transferNote: string
    agentId: string
    index: number
    messageMetaData: {
        contactId: string
        messageId: string
        isRead: boolean
        isDelivered: boolean
    }
    isCustomerTranslatedMsg: boolean
    callBackEvent: (eventType: string, eventData: object) => void
}

const TranscriptView = (props: propsData) => {
    const { item, chatStatus, lang, darkMode, transferNoteFound, transferNote, agentId, isCustomerTranslatedMsg = false,
        messageMetaData = {
            contactId: '',
            messageId: '',
            isRead: false,
            isDelivered: false
        }
    } = props;


    useEffect(() => { }, [messageMetaData.messageId, messageMetaData.isRead, messageMetaData.isDelivered, isCustomerTranslatedMsg])

    const getFastFollowFlags = () => {
        try {
            const localData: any = localStorage.getItem('fastFollowFlags');
            return JSON.parse(localData) || {}
        } catch (e) {
            console.log('fastFollowFlags fail', e)
            return {}
        }
    }

    const getSystemMessage = (contentType: any, name: string, participantRole: string) => {
        switch (contentType) {
            case "application/vnd.amazonaws.connect.event.chat.ended":
                return localization[lang].CHAT_ENDED;
            case "application/vnd.amazonaws.connect.event.participant.joined":
                if (participantRole === 'AGENT') {
                    const agentJoinedString = localization[lang].AGENT_JOINED.toString();
                    const agentJoinedWithName = agentJoinedString.replace("{agentNickname}", name)
                    return agentJoinedWithName;
                }
                if (participantRole === 'SUPERVISOR') {
                    let supervisorJoinedString = localization[lang].SUPERVISOR_JOINED.toString();
                    let supervisorJoinedWithName = supervisorJoinedString.replace("[Supervisor Nickname]", name)
                    return supervisorJoinedWithName;
                }
                return `${name} ${localization[lang].PARTICIPANT_JOINED}`
            case "application/vnd.amazonaws.connect.event.participant.left":
                if (participantRole === 'AGENT') {
                    const agentLeftString = localization[lang].AGENT_LEFT.toString();
                    const agentLeftWithName = agentLeftString.replace("{agentNickname}", name)
                    return agentLeftWithName;
                }
                if (participantRole === 'SUPERVISOR') {
                    let supervisorLeftString = localization[lang].SUPERVISOR_LEFT.toString();
                    let supervisorLeftWithName = supervisorLeftString.replace("[Supervisor Nickname]", name)
                    return supervisorLeftWithName;
                }
                return `${name} ${localization[lang].PARTICIPANT_LEFT}`
            case "application/vnd.amazonaws.connect.event.transfer.succeeded":
                return localization[lang].TRANSFER_SUCCEEDED;
            case "application/vnd.amazonaws.connect.event.transfer.failed":
                return localization[lang].TRANSFER_FAILED;
            default: return ''
        }
    }

    const isJson = (str: string) => {
        try {
            const keys: string[] = ["content", "genericAttachments", "contentType"]
            const json = JSON.parse(str);
            if (typeof json !== 'object' || json == null) return false
            return Object.keys(json).every(element => keys.includes(element))
        } catch (e) {
            return false;
        }
    }

    const phoneNumberTolink = (input: string): string => {
        const phoneNumber = findPhoneNumbersInText(input);
        if (phoneNumber.length > 0) {
            const number = phoneNumber[0]?.number?.number;
            input = input.toString().replace(number, `[${number}](${number})`)
        }
        return input
    }

    const getFormattedContent = (content: any, participantRole: string, translateMsg: string = '') => {
        try {
            if (findPhoneNumbersInText(content).length > 0 && !isJson(content)) content = phoneNumberTolink(content)
            const obj = isJson(content) ? JSON.parse(content) : content.trim()
            if (obj && typeof obj === 'object') {
                return (
                    <div>
                        {(obj?.genericAttachments[0]?.title) &&
                            <View marginTop={13} UNSAFE_className={styles['ccp-chat-text']} borderWidth="thin" borderColor={(!darkMode && participantRole === 'CUSTOMER') || (participantRole !== 'CUSTOMER') ? 'transparent' : 'dark'} borderRadius="medium" paddingX="15px" paddingY="8px"
                                backgroundColor={participantRole !== 'CUSTOMER' ? 'static-blue' : 'gray-50'}><ReactMarkdown disallowedElements={['script', 'iframe', 'style']} unwrapDisallowed={true} className={styles['ccp-chat-text']}>{sanitizeContent(obj.genericAttachments[0].title)}</ReactMarkdown></View>
                        }
                        {obj?.genericAttachments[0]?.buttons.map((button: any) => {
                            return (<div key={Math.floor(Math.random() * 100000)}>
                                <ActionButton width="242px" max-width="242" UNSAFE_className={styles['bot-message-button']} alignSelf="end" isDisabled>{button.text}</ActionButton>
                            </div>)
                        })}
                    </div>
                )
            }
            if (typeof obj === "boolean") {
                return (
                    <div>
                        <View marginTop={13} UNSAFE_className={styles['ccp-chat-text']} borderWidth="thin" borderColor={(!darkMode && participantRole === 'CUSTOMER') || (participantRole !== 'CUSTOMER') ? 'transparent' : 'dark'} borderRadius="medium" paddingX="15px" paddingY="8px"
                            backgroundColor={participantRole !== 'CUSTOMER' ? 'static-blue' : 'gray-50'}>
                            {obj.toString()}
                        </View>
                    </div>
                )
            }
            return (
                <div>
                    <View marginTop={13} UNSAFE_className={styles['ccp-chat-text']} borderWidth="thin" borderColor={(!darkMode && participantRole === 'CUSTOMER') || (participantRole !== 'CUSTOMER') ? 'transparent' : 'dark'} borderRadius="medium" paddingX="15px" paddingY="8px"
                        backgroundColor={participantRole !== 'CUSTOMER' ? 'static-blue' : 'gray-50'}>
                        {
                            (getFastFollowFlags()?.ALLOWED_CONTENT_TYPE_JSON && participantRole === 'AGENT' && translateMsg && chatStatus === 'Closed') &&
                            <ReactMarkdown disallowedElements={['script', 'iframe', 'style']} unwrapDisallowed={true} components={{ a: LinkRenderer }} className={`${styles['agent-link']} ${styles['ccp-agent-pre-translate-msg']}`}>
                                {sanitizeContent(translateMsg)}
                            </ReactMarkdown>
                        }
                        <ReactMarkdown disallowedElements={['script', 'iframe', 'style']} unwrapDisallowed={true} components={{ a: LinkRenderer }} className={participantRole !== 'CUSTOMER' ? styles['agent-link'] : styles['customer-link']}>
                            {sanitizeContent(obj)}
                        </ReactMarkdown>
                    </View>
                </div>
            )
        } catch (e) {
            return getOriginalContent(content, participantRole, translateMsg)
        }
    }

    const linkOnClick = (e: any) => {
        e.preventDefault();
        try {
            const phoneInText = findPhoneNumbersInText(e.target.href);
            if (phoneInText.length > 0) {
                const phoneNumber = phoneInText[0].number.number;
                return props.callBackEvent("phone-in-transcript", { phoneNumber });
            }
            else {
                window.open(e.target.href, "_blank", "noreferrer")
            }
        } catch (e) {
            console.log('error in linkOnClick', e)
        }
    }

    const LinkRenderer = (props: any) => {
        return (
            <a href={props.href} onClick={linkOnClick}>
                {props.children}
            </a>
        );
    }

    const getOriginalContent: any = (content: any, participantRole: string, translateMsg: string = '') => {
        let newContent: any = ""
        try {
            const obj = JSON.parse(content)
            newContent = obj.content ? obj.content : JSON.stringify(obj)

        } catch (error) {
            newContent = content
        }
        return (
            <div>
                <View marginTop={13} UNSAFE_className={styles['ccp-chat-text']} borderWidth="thin" borderColor={(!darkMode && participantRole === 'CUSTOMER') || (participantRole !== 'CUSTOMER') ? 'transparent' : 'dark'} borderRadius="medium" paddingX="15px" paddingY="8px"
                    backgroundColor={participantRole !== 'CUSTOMER' ? 'static-blue' : 'gray-50'}>
                    {
                        (getFastFollowFlags()?.ALLOWED_CONTENT_TYPE_JSON && participantRole === 'AGENT' && translateMsg && chatStatus === 'Closed') &&
                        <ReactMarkdown disallowedElements={['script', 'iframe', 'style']} unwrapDisallowed={true} components={{ a: LinkRenderer }} className={`${styles['agent-link']} ${styles['ccp-agent-pre-translate-msg']}`}>
                            {sanitizeContent(translateMsg)}
                        </ReactMarkdown>
                    }
                    <ReactMarkdown disallowedElements={['script', 'iframe', 'style']} unwrapDisallowed={true} components={{ a: LinkRenderer }} className={participantRole !== 'CUSTOMER' ? styles['agent-link'] : styles['customer-link']}>
                        {sanitizeContent(newContent)}
                    </ReactMarkdown>
                </View>
            </div>
        )
    }

    const getAttachmentMessage = (item: any) => {
        let supervisiorClassName = ""
        if (item.ParticipantRole === 'SUPERVISOR') {
            supervisiorClassName = "supervisior-attachment-text"
        }
        return (
            <div>
                <View marginTop={13} UNSAFE_className={`${styles['ccp-chat-text']} ${styles['ccp-chat-attachment-message']}`} borderWidth="thin" borderColor={(!darkMode && (item.ParticipantRole === 'CUSTOMER')) || (item.ParticipantRole !== 'CUSTOMER') ? 'transparent' : 'dark'} borderRadius="medium" paddingX="15px" paddingY="8px"
                    backgroundColor={item.ParticipantRole !== 'CUSTOMER' ? 'static-blue' : 'gray-50'}>
                    <div><Attach className={(item.ParticipantRole !== 'CUSTOMER') ? styles['ccp-attach-icon-white'] : styles['ccp-attach-icon-black']} /></div>
                    <div className={styles['attachment-name']}>
                        <div className={`${styles['attachment-file-name']} ${styles[supervisiorClassName]}`} >{item.Attachments[0].AttachmentName}</div>
                        <div className={`${styles[supervisiorClassName]}`} >{(chatStatus !== 'Closed') ? localization[lang].FILE_UPLOAD_SUCCESS : localization[lang].FILE_NO_LONGER_AVAILABLE}</div>
                    </div>
                    {
                        (chatStatus !== 'Closed') &&
                        <div className={styles['file-download-button']} onClick={downloadAttachment}><Download color={item.ParticipantRole === 'CUSTOMER'} /></div>
                    }
                </View>
            </div>
        )
    }

    const downloadAttachment = () => {
        props.callBackEvent("downloadAttachment", { attachment: item });
    }

    const getReadDeliveredText = (item: any) => {
        let status = ''
        if (chatStatus === 'Closed') return status;
        if (item?.Type === "MESSAGE" && item?.ParticipantRole === "AGENT") {
            if (item?.isRead) {
                status = `| ${localization[lang].READ}`;
            } else if (item?.isDelivered) {
                status = `| ${localization[lang].DELIVERED}`;
            }
        }
        return status;
    }

    const getItemMessage = (item: any) => {
        let messageContent = item?.Content || '';
        let translateContent = '';

        /**
         * try catch block use because of the we cannot rely on the contentType to implement the logic
         * - item.ContentType 'text/plain' somte Content get json string object 
         * - in translationTranscript array we are storing Content as string not the object for contentType application/json
         */
        try {
            const jsonContent = JSON.parse(messageContent) || {};
            if (item.ContentType === 'application/json' && jsonContent?.message) {
                messageContent = jsonContent?.message || '';
                translateContent = jsonContent?.metadata?.preTranslationText || jsonContent?.message || '';
            }
        } catch (err) { }

        return {
            messageContent,
            translateContent
        }
    }

    const getTranscriptView = () => {
        if (item.Type === "EVENT" || item.DisplayName === "SYSTEM_MESSAGE") {
            if (transferNoteFound && transferNote && item.Content === transferNote) {
                return (
                    <div className={styles['ccp-chat-agent-message']}>
                        <View marginTop={13} borderWidth="thin" borderColor='transparent' borderRadius="medium" paddingX="14px" paddingY="8px" maxWidth="270px"
                            UNSAFE_className={`${styles['transfer-note']}`} >
                            <div><LockClosed /></div>
                            <div className={styles['transfer-content']}>{item.Content}</div>
                        </View>
                        <Flex direction="row">
                            <View UNSAFE_className={styles['private-label']}>Private</View>
                            <Text UNSAFE_className={`${styles['ccp-chat-text-small']} ${styles['ccp-chat-message-subtext-' + (darkMode ? 'dark' : 'light')]}`}>
                                {agentId} | {new Date(item.AbsoluteTime).toLocaleTimeString([], { hour12: true, hour: '2-digit', minute: '2-digit' })}
                            </Text>
                        </Flex>
                    </div>
                )
            }
            return (
                <Flex justifyContent="center" marginTop="5px">
                    <Text UNSAFE_className={`${styles['ccp-chat-system-message']} ${styles['ccp-chat-message-subtext-' + (darkMode ? 'dark' : 'light')]}`}>
                        <div className={styles['ccp-agent-joined']}>
                            {item.Type === 'EVENT' && item.ContentType === 'application/vnd.amazonaws.connect.event.participant.joined' && item.ParticipantRole === 'AGENT' &&
                                `${item.DisplayName} ${localization[lang].PARTICIPANT_JOINED} | ${new Date(item.AbsoluteTime).toLocaleTimeString([], { hour12: true, hour: '2-digit', minute: '2-digit' })}\r\n`}
                        </div>
                        {(item.Type === "EVENT") && `${getSystemMessage(item.ContentType, item.DisplayName, item.ParticipantRole)} | ${new Date(item.AbsoluteTime).toLocaleTimeString([], { hour12: true, hour: '2-digit', minute: '2-digit' })}  `}
                        {(item.Type === "MESSAGE") && `${item.Content} | ${new Date(item.AbsoluteTime).toLocaleTimeString([], { hour12: true, hour: '2-digit', minute: '2-digit' })}`} {getReadDeliveredText(item)}
                    </Text>
                </Flex>
            )
        }

        if (item.Type === "ATTACHMENT") {
            let style = 'ccp-chat-customer-message'
            if (item.ParticipantRole === 'AGENT' || item.ParticipantRole === 'SYSTEM') style = 'ccp-chat-agent-message';
            if (item.ParticipantRole === 'SUPERVISOR') style = 'ccp-chat-supervisor-message';
            return (
                <div className={styles[style]}>
                    {getAttachmentMessage(item)}
                    <Text UNSAFE_className={`${styles['ccp-chat-text-small']} ${styles['ccp-chat-message-subtext-' + (darkMode ? 'dark' : 'light')]}`}>
                        {item.DisplayName === 'BOT' ? localization[lang].BOT : item.DisplayName} | {new Date(item.AbsoluteTime).toLocaleTimeString([], { hour12: true, hour: '2-digit', minute: '2-digit' })} {getReadDeliveredText(item)}
                    </Text>
                </div>
            )
        }

        return (
            <div className={(item.ParticipantRole === 'AGENT' || item.ParticipantRole === 'CUSTOM_BOT' || item.ParticipantRole === 'SYSTEM') ? styles['ccp-chat-agent-message'] : styles['ccp-chat-customer-message']}>
                {!item.Content.includes('CUSTOM_SYSTEM_MESSAGE_') && getFormattedContent(getItemMessage(item).messageContent, item.ParticipantRole, getItemMessage(item).translateContent)}
                {!item.Content.includes('CUSTOM_SYSTEM_MESSAGE_') && <Text UNSAFE_className={`${styles['ccp-chat-text-small']} ${styles['ccp-chat-message-subtext-' + (darkMode ? 'dark' : 'light')]}`}>
                    {item.DisplayName === 'BOT' ? localization[lang].BOT : item.DisplayName} | {new Date(item.AbsoluteTime).toLocaleTimeString([], { hour12: true, hour: '2-digit', minute: '2-digit' })} {getReadDeliveredText(item)}
                </Text>}
            </div>
        )
    }

    return (
        <div>
            {getTranscriptView()}
        </div>
    )
}


export default TranscriptView;