import getCases from '../../api/getCasesById';

type getCasePayload = {
  conversationId: string;
  rengaId: string;
};

export const getCaseAPI = (payload: getCasePayload) => {
  return (dispatch: any) => {
    console.log('Triggered getCaseAPI with Payload', payload);
    getCases(payload.conversationId, payload.rengaId).then(data => {
      dispatch({ type: 'GET_CASES_RESPONSE', payload: data });
    });
  };
};

type setActiveCaseType = {
  guid: string;
  id: string;
  linkedByAgent: string;
  caseCreated: boolean;
};

export const setActiveCase = (payload: setActiveCaseType) => {
  console.log('Triggered setActiveCase with Payload', payload);
  return { type: 'SET_ACTIVE_CASE', payload };
};

export const clearCaseData = (payload: any) => {
  console.log('Triggered clearCaseData with Payload', payload);
  return { type: 'CLEAR_CASE_DATA', payload };
};

export const newCaseCreated = (payload: boolean) => {
  console.log('Triggered newCaseCreated with Payload', payload);
  return { type: 'NEW_CASE_CREATED', payload };
};

export const returnCustomerCaseLinked = (payload: boolean) => {
  console.log('Triggered returnCustomerCaseLinked with Payload', payload);
  return { type: 'RETURN_CUSTOMER_CASE_LINKED', payload };
};
