import getClient from '../utils/client';
type apiPayload = {
  conversationId: string;
  contactGuid: string;
};

export default async function updateContactGuid(payload: apiPayload): Promise<any> {
  return getClient()
    .put(`/api/v1/customer/guid`, payload)
    .then(res => {
      const receivedRes = res.data ? res.data : {};
      console.log('oc-ccm-module, updateContactGuidResponse :', JSON.stringify(receivedRes));
      return receivedRes;
    })
    .catch(e => {
      console.log('oc-ccm-module, updateContactGuidResponse : Exception Occured', e);
      return {};
    });
}
