import { ProgressCircle } from '@adobe/react-spectrum'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { connect } from 'react-redux'

import {
  loadCustomer,
  readConversationDetailsFromDB, validateCustomer
} from '../api/customerApi'
import localization from '../language/localization'
import {
  clearCaseState,
  setActiveCase,
  setCaseCreatedFlag,
  setCaseList,
  setCreateCaseParams
} from '../store/actions/caseAction'
import {
  clearCustomerState,
  setCustomerDetailsFromDB,
  setGuidForCustomerDeeplink,
  setLoadCustomerData,
  setPersistantCustomerDetails
} from '../store/actions/customerAction'
import {
  allowToLinkCaseForReturnCustomer,
  clearOCState,
  setActiveConversationData,
  setContextData,
  setConversationArray
} from '../store/actions/ocAction';
import {
  clearUIState,
  setAgentConsoleServiceBaseUrl,
  setCaseDeeplinkURL, setChangeCustomer, setCustomerDeeplinkURL,
  setLanguage,
  setReadOnlyMode, setServiceBaseUrl,
  toggleDarkMode,
  toggleNotification
} from '../store/actions/uiActions'
import darkTheme from '../Theme/Styles/main/dark.css'
import lightTheme from '../Theme/Styles/main/light.css'
import { createCancelToken, deleteCancelToken } from '../utils/client'
import {
  getSessionStorage,
  removeConvDataFromSessionStorage,
  setSessionStorage
} from '../utils/sessionStorage'
import { parseContextData } from '../utils/utils'
import CaseHeader from './CaseHeader'
import CaseList from './CaseList'
import ChangeCustomer from './ChangeCustomer'
import CreateCase from './CreateCase'
import EnterpriseCaseView from './EnterpriseCaseView'
import Notification from './Notification'
import SearchCustomer from './SearchCustomer'
import Zendesk from './Zendesk' 

export interface HomeProps {
  isCaseListPage: boolean
  darkMode: boolean
  language: string
  contextData: any
  toggleNotification: boolean
  notificationMessage: string
  conversationIdArray: Array<string>
  allowToLinkCaseForReturnCustomer: boolean
  callBackEvent: (eventType: string, eventData: object) => void
  setCaseListDispatch: (data: any) => void
  setCustomerDispatch: (data: any, localizeString: any, agentDetails: any, callChangeCustomer?: boolean) => void
  clearUIStateDispatch: (data: any) => void
  clearCustomerStateDispatch: (data: any) => void
  clearCaseStateDispatch: (data: any) => void
  setActiveConversationData: (data: any) => void
  toggleDarkMode: (flag: boolean) => void
  setLanguage: (language: string) => void
  setCustomerDeeplinkUrl: (url: string) => void
  setCaseDeeplinkUrl: (url: string) => void
  setServiceBaseUrl: (url: string) => void
  setAgentConsoleServiceBaseUrl: (url: string) => void
  setContextDataDispatch: (payload: any) => void
  clearOCStateDispatch: (payload: any) => void
  setReadOnlyModeDispatch: (flag: boolean) => void
  setActiveCaseDispatch: (payload: any) => void
  setCustomerDetailsFromDBDispatch: (payload: any) => void
  toggleNotificationDispatch: (flag: boolean, message: string) => void
  setCreateCaseParamsDispatch: (payload: any) => void
  setGuidForCustomerDeeplinkDispatch: (payload: any) => void
  setChangeCustomerFlagDispatch: (payload: any) => void
  setPersistantCustomerDispatch: (payload: any) => void
  setCaseCreatedFlagDispatch: (flag: boolean) => void
  setConversationArrayDispatch: (payload: Array<string>) => void
  setReturnCustomerFlagDispatch: (flag: boolean) => void
}

export interface HomeState {
  status: string
  isLoading: boolean
  loaderMessage: string
  currentConversationId: string
  conversationStatus: boolean
  showCaseListPage: boolean
  showNavigateToDynamicsPage: boolean
  pageReady: boolean
  showBackButton: boolean
  showSearchCustomer: boolean
  showZendeskPage: boolean
}

const DEFAULT_CLEAR_CUSTOMER_LINKED_NOTIFICATION_TIMEOUT = 1000;
const CASE_LINKED_NOTIFICATION_TIMEOUT = 2000;
const CLEAR_CASE_LINKED_NOTIFICATION_TIMEOUT = 4000;

class Home extends React.Component<HomeProps, HomeState> {
  currentConversationId: string
  currentAssignedAgentStatus: any
  zendeskUrl: string
  constructor(props: HomeProps) {
    super(props)
    this.currentConversationId = ''
    this.zendeskUrl = ''
    this.currentAssignedAgentStatus = undefined
    this.state = {
      status: 'Active',
      isLoading: true,
      pageReady: false,
      loaderMessage: '',
      currentConversationId: '',
      showCaseListPage: false,
      conversationStatus: false,
      showNavigateToDynamicsPage: false,
      showBackButton: false,
      showSearchCustomer: false,
      showZendeskPage: false
    }
  }

  componentDidMount() {
    createCancelToken()
    this.resetState()
    this.props.callBackEvent('EVENT_NAME', { message: 'EVENT_MESSAGE' })
    const contextData = parseContextData(this.props)
    console.log('ecm: Component did mount:', contextData)
    this.updateContextData(contextData)
  }

  toggleToast = (status: boolean, value: string) => {
    this.props.toggleNotificationDispatch(status, value);
  }

  /* istanbul ignore next */
  updateContextData = (dataProps: any, callChangeCustomer?: boolean) => {
    const {
      rengaId,
      orgId,
      firstName,
      lastName,
      conversationId,
      agentName,
      customerEmail,
      agentEmail,
      closedConversation,
      originalQueue,
      assignedAgent,
      phoneNumber: phone,
      isZendeskSkill
    } = dataProps.contextData.enterpriseContextData
    this.props.setConversationArrayDispatch(dataProps.contextData.alterChannelConversationId)
    console.log('ecm: Update context data call', dataProps)
    this.props.setCreateCaseParamsDispatch({ caseTitle: '', caseCategory: '' })
    const {
      REACT_APP_ECM_BASE_URL: baseServiceUrl,
      REACT_APP_CUSTOMER_DEEPLINK_URL: customerDeeplinkUrl,
      REACT_APP_CASE_DEEPLINK_URL: setCaseDeeplinkUrl,
      AGENT_CONSOLE_SERVICE_BASE_URL: agentConsoleBaseUrl,
      REACT_APP_ECM_SEARCH_CUSTOMER_URL
    } = dataProps.contextData.env_secrets
    this.zendeskUrl = REACT_APP_ECM_SEARCH_CUSTOMER_URL
    const language = dataProps.contextData.lang
    const localizeString = localization[language]
    this.currentConversationId = conversationId
    this.currentAssignedAgentStatus = assignedAgent
    this.setState({ currentConversationId: conversationId, isLoading: false })
    this.setState({ conversationStatus: closedConversation })
    this.props.setServiceBaseUrl(baseServiceUrl)
    this.props.setAgentConsoleServiceBaseUrl(agentConsoleBaseUrl)
    this.props.setCustomerDeeplinkUrl(customerDeeplinkUrl)
    this.props.setCaseDeeplinkUrl(setCaseDeeplinkUrl)
    this.props.toggleDarkMode(dataProps.contextData.darkMode)
    this.props.setLanguage(language)
    this.props.setContextDataDispatch(dataProps.contextData)
    this.props.setReadOnlyModeDispatch(closedConversation)
    const { status } = this.state
    const cacheData = getSessionStorage(conversationId)
    const { cachedOrgId, cachedRengaId, cachedFirstName, cachedLastName, phone: cachedPhone = "" } = cacheData || {}
    const currentRengaId = rengaId || cachedRengaId;
    const currentOrgId = orgId || cachedOrgId;
    const currentFirstName = firstName || cachedFirstName;
    const currentLastName = lastName || cachedLastName;
    const currentPhone = phone || cachedPhone;
    if (isZendeskSkill) {
      this.setState({ showZendeskPage: true })
      loadCustomer(conversationId, {
        rengaId : currentRengaId ?? currentPhone,
        orgId : "NA",
        firstName : currentFirstName,
        lastName : "NA",
        orgGuid :"NA",
        c360Guid : "NA",
        email: customerEmail,
        phone: 'NA'
      }).then(() => {
        console.log("Zendesk User information Updated")
      }).catch((err) => { console.log("Error in updating Zendesk User information", err) })
    }
    else if (!originalQueue.toLowerCase().startsWith('ent-')) {
      this.setState({
        showNavigateToDynamicsPage: true
      })
    } else if (closedConversation) {
      this.readConversationDetails(
        conversationId,
        localizeString,
        agentName,
        agentEmail
      )
    } else {
      this.setCustomer(
        conversationId,
        {
          currentRengaId,
          currentOrgId,
          currentFirstName,
          currentLastName,
          customerEmail,
          currentPhone
        },
        agentEmail,
        agentName,
        status,
        localizeString,
        callChangeCustomer
      )
    }
  }

  /* istanbul ignore next */
  async readConversationDetails(
    conversationId: string,
    localizeString: any,
    agentEmailFromContextData: string = '',
    agentNameFromContextData: string = ''
  ) {
    this.currentConversationId = conversationId

    this.setState({
      isLoading: true,
      loaderMessage: localizeString['loading_customer_details-msg'],
      currentConversationId: conversationId
    })

    try {
      const conversationDetails = await readConversationDetailsFromDB(
        conversationId
      )

      if (
        conversationDetails &&
        conversationDetails.data &&
        conversationDetails.data.success &&
        conversationDetails.data.json
      ) {
        const {
          linkedCustomer: {
            orgId = '',
            rengaId = '',
            firstName = '',
            lastName = '',
            email: customerEmail = '',
            phone = ''
          } = {},
          linkedCaseData_DME: { id: caseId = '', guid = '' } = {}
        } = conversationDetails.data.json

        //No linked customer is found
        if (!(orgId && rengaId)) {
          this.setState({ showSearchCustomer: true, showCaseListPage: false, pageReady: true })
        }
        else {
          this.props.setActiveCaseDispatch({ caseId, guid })
          this.props.setCustomerDetailsFromDBDispatch(
            conversationDetails.data.json
          )
          this.toggleToast(true, localizeString.customer_selected_notification)
          console.log(
            'conversationDetails.data.json',
            conversationDetails.data.json
          )
          this.props.setActiveConversationData({
            rengaId,
            orgId,
            firstName,
            lastName,
            conversationId,
            agentNameFromContextData,
            customerEmail,
            agentEmailFromContextData,
            phone
          })
          if (caseId) {
            setTimeout(this.toggleToast, CASE_LINKED_NOTIFICATION_TIMEOUT, true, localizeString.case_linked_notification)
            setTimeout(this.toggleToast, CLEAR_CASE_LINKED_NOTIFICATION_TIMEOUT, false, '')
          } else {
            setTimeout(this.toggleToast, DEFAULT_CLEAR_CUSTOMER_LINKED_NOTIFICATION_TIMEOUT, false, '')
          }
          this.setState({ showCaseListPage: true, pageReady: true, isLoading: false });
          this.setCurrentViewToCache(true, false, false);
          try {
            const validateCustomerResponse = await validateCustomer(
              conversationId,
              rengaId,
              orgId
            )
            let c360Guid = ''
            if (
              validateCustomerResponse &&
              validateCustomerResponse.data &&
              validateCustomerResponse.data.success &&
              validateCustomerResponse.data.json
            ) {
              c360Guid = validateCustomerResponse.data.json.c360Guid
                ? validateCustomerResponse.data.json.c360Guid
                : ''
            }
            this.props.setGuidForCustomerDeeplinkDispatch(c360Guid)
          } catch (e) {
            if (
              e &&
              e.message &&
              e.message.includes('ECM API calls cancelled on umnmount')
            ) {
              console.log('ecm: validate customer cancelled call for closed conversation', conversationId, e)
              this.toggleLoader(true, '')
            } else {
              console.error('ecm: validate customer call for closed conversation', conversationId, e)
            }
          }
        }
      }
    } catch (err) {

      if (
        err &&
        err.message &&
        err.message.includes('ECM API calls cancelled on umnmount')
      ) {
        console.log('ecm: read conversation details cancelled for coversationId', conversationId, err)
        this.toggleLoader(true, '')
      } else {
        console.error('ecm: read conversation details error', conversationId, err)
      }
    }
  }

  /* istanbul ignore next */
  componentWillUnmount() {
    deleteCancelToken()
  }

  /* istanbul ignore next */

  refreshWidget = () => {
    const contextData = parseContextData(this.props)
    removeConvDataFromSessionStorage(this.state.currentConversationId)
    this.resetState()
    //reset searchcustomer
    this.updateContextData(contextData)
  }

  /* istanbul ignore next */
  resetState() {
    console.log('ecm: reset customer')
    this.props.clearCaseStateDispatch('')
    this.props.clearCustomerStateDispatch('')
    this.props.clearOCStateDispatch('')
    this.props.clearUIStateDispatch('')
  }

  /* istanbul ignore next */
  async componentWillReceiveProps(nextProps: any) {
    console.log('ecm: Component will receive props:', nextProps)
    const parsedData = parseContextData(nextProps)
    const {
      conversationId: newConversationId,
      closedConversation: newClosedConversation,
      assignedAgent: newAssignedAgent
    } = parsedData.contextData.enterpriseContextData
    const { darkMode: newDarkMode, lang: newLang } = parsedData.contextData;
    const localizeString = localization[newLang]

    if (this.props.conversationIdArray.length !== parsedData.contextData.alterChannelConversationId.length) {
      this.props.setConversationArrayDispatch(parsedData.contextData.alterChannelConversationId)
    }

    if (newDarkMode !== this.props.darkMode) {
      console.log('ecm: Component will receive props, Theme changed')
      this.props.toggleDarkMode(newDarkMode)
    }
    if (newLang !== this.props.language) {
      console.log('ecm: Component will receive props, Language changed')
      this.props.setLanguage(newLang)
    }
    if (newClosedConversation !== this.state.conversationStatus) {
      console.log('ecm: conversation status changed')
      this.props.setReadOnlyModeDispatch(newClosedConversation)
    }
    if (typeof newAssignedAgent == "boolean" && newAssignedAgent != this.currentAssignedAgentStatus) {
      this.refreshWidget();
    }
    if (newConversationId !== this.currentConversationId) {
      this.currentConversationId = newConversationId
      this.toggleLoader(true, localizeString['loading_customer_details-msg'])
      deleteCancelToken()
      createCancelToken()
      this.resetState()
      this.setState({ showNavigateToDynamicsPage: false, showZendeskPage: false })
      console.log('ecm: Component will receive props, conversationId changed')
      console.log(
        'ecm: current conversation',
        this.state.currentConversationId + ' new conversation ',
        newConversationId,
        parsedData
      )
      console.log(
        'ecm: current conversation',
        this.state.currentConversationId + ' new conversation ',
        newConversationId,
        nextProps
      )
      this.updateContextData(parsedData)
      this.setState({ showCaseListPage: true })
      this.setCurrentViewToCache(true, false, false);
    }
  }

  async authenticatedUser(conversationId: string, rengaId: string, orgId: string,
    firstName: string,
    lastName: string,
    customerEmail: string,
    customerPhone: string,
    agentEmail: string,
    agentName: string,
    localizeString: any,
    callChangeCustomer?: boolean
  ) {
    let orgGuid = '';
    let c360Guid = '';
    try {
      const validateCustomerResponse = await validateCustomer(conversationId, rengaId, orgId);
      const {
        data: {
          success = false,
          json: {
            orgGuid: responseOrgGuid = '',
            c360Guid: responsec360Guid = ''
          } = {}
        } = {}
      } = validateCustomerResponse || {}
      if (success) {
        orgGuid = responseOrgGuid;
        c360Guid = responsec360Guid;
        this.props.setGuidForCustomerDeeplinkDispatch(c360Guid)
      }

      if (callChangeCustomer) {
        const arrayOfConv = this.props.conversationIdArray;
        this.props.setCustomerDispatch(
          {
            conversationId,
            rengaId,
            orgId,
            firstName,
            lastName,
            orgGuid,
            c360Guid,
            customerEmail,
            customerPhone,
            arrayOfConv
          },
          localizeString,
          {
            agentEmail,
            agentName
          },
          callChangeCustomer
        )
        return {};
      }

      const loadCustomerResponse = await loadCustomer(conversationId, {
        rengaId,
        orgId,
        firstName,
        lastName,
        orgGuid,
        c360Guid,
        email: customerEmail,
        phone: customerPhone
      });
      const { success: loadCustomerSuccess, json, persistCustomer } = loadCustomerResponse.data;
      if (loadCustomerSuccess && json && persistCustomer) {
        const { linkedCustomer: { firstName = '', lastName = '', rengaId = '', orgId = '', email = '', phone = '' } = {} } = {} = json
        /** return customer */
        const {
          linkedCaseData_DME: {
            id: caseId = '',
            guid = '',
            caseCreated = false
          } = {},
          //TO DO: check default value
          ecmReturnCustomerCaseLinked = false
        } = json
        
        this.props.setActiveCaseDispatch({ caseId, guid });
        this.props.setReturnCustomerFlagDispatch(ecmReturnCustomerCaseLinked)
        this.props.setCaseCreatedFlagDispatch(caseCreated)
        console.log("ecm: auth flow ", firstName, lastName, rengaId, orgId, email);
        this.props.setPersistantCustomerDispatch(json);
        if (firstName && lastName && rengaId && orgId) {
          this.toggleToast(true, localizeString.customer_selected_notification)
        }
        if (caseId) {
          setTimeout(this.toggleToast, CASE_LINKED_NOTIFICATION_TIMEOUT, true, localizeString.case_linked_notification)
          setTimeout(this.toggleToast, CLEAR_CASE_LINKED_NOTIFICATION_TIMEOUT, false, '')
        } else {
          setTimeout(this.toggleToast, DEFAULT_CLEAR_CUSTOMER_LINKED_NOTIFICATION_TIMEOUT, false, '')
        }
        return Promise.resolve({ cancelled: false, firstName, lastName, rengaId, orgId, email, phone });
      } else if (loadCustomerSuccess && json && !persistCustomer) {
        this.props.setActiveConversationData({
          rengaId,
          orgId,
          firstName,
          lastName,
          conversationId,
          agentName,
          customerEmail,
          customerPhone,
          agentEmail
        })
        // Set returnCustomerFlag to false for fisrt time load
        this.props.setReturnCustomerFlagDispatch(false)
        return Promise.resolve({ cancelled: false });
      }
      return Promise.resolve({ cancelled: false });
    } catch (err) {
      // Toggle loader to false if API calls had error
      if (err && err.message && err.message.includes('ECM API calls cancelled on umnmount')) {
        console.log('ecm: set customer calls cancelled for conversationId', conversationId, err)
        return Promise.resolve({ cancelled: true })
      } else {
        console.error('ecm: set customer error', err);
        this.toggleLoader(false, '')
      }
      return Promise.resolve();
    }

  }

  async unAuthenticatedUser(conversationId: string, localizeString: any) {
    // const {rengaId, orgId, firstName, lastName, customerEmail}
    try {
      const loadCustomerResponse = await loadCustomer(conversationId, {});
      const { success = false, json = null, persistCustomer = false } = loadCustomerResponse && loadCustomerResponse.data ? loadCustomerResponse.data : {};
      if (success && json && persistCustomer) {
        const { linkedCustomer: { firstName = '', lastName = '', rengaId = '', orgId = '', email = '', phone = '' } = {} } = {} = json
        console.log("ecm: unAuth flow response", json);
        const {
          linkedCaseData_DME: {
            id: caseId = '',
            guid = '',
            caseCreated = false
          } = {}
        } = json
        this.props.setActiveCaseDispatch({ caseId, guid });
        this.props.setCaseCreatedFlagDispatch(caseCreated)
        this.props.setPersistantCustomerDispatch(json);
        if (firstName && lastName && rengaId && orgId) {
          this.toggleToast(true, localizeString.customer_selected_notification)
        }
        if (caseId) {
          setTimeout(this.toggleToast, CASE_LINKED_NOTIFICATION_TIMEOUT, true, localizeString.case_linked_notification)
          setTimeout(this.toggleToast, CLEAR_CASE_LINKED_NOTIFICATION_TIMEOUT, false, '')
        } else {
          setTimeout(this.toggleToast, DEFAULT_CLEAR_CUSTOMER_LINKED_NOTIFICATION_TIMEOUT, false, '')
        }
        if (firstName && lastName && rengaId && orgId) {
          return Promise.resolve({ cancelled: false, firstName, lastName, rengaId, orgId, email, phone });
        }
      }
      return Promise.resolve({ cancelled: false });
    } catch (err) {
      // Toggle loader to false if API calls had error
      if (err && err.message && err.message.includes('ECM API calls cancelled on umnmount')) {
        console.log('ecm: unauthenticated user cancelled for conversationId', conversationId, err)
        return Promise.resolve({ cancelled: true })
      } else {
        console.error('ecm: unauthenticated user error', err);
        this.toggleLoader(false, '')
      }
      return Promise.resolve({});
    }
  }


  /* istanbul ignore next */
  async setCustomer(
    conversationId: string,
    customerDetails: any,
    agentEmail: string,
    agentName: string,
    status: string,
    localizeString: any,
    callChangeCustomer?: boolean
  ) {
    let { currentRengaId: rengaId, currentOrgId: orgId, currentFirstName: firstName, currentLastName: lastName, customerEmail, currentPhone: customerPhone } = customerDetails;
    this.currentConversationId = conversationId
    this.setState({
      isLoading: true,
      loaderMessage: localizeString['loading_customer_details-msg'],
      currentConversationId: conversationId
    })
    try {
      if (orgId && rengaId) {
        const response: any = await this.authenticatedUser(conversationId, rengaId, orgId, firstName, lastName, customerEmail, customerPhone, agentEmail, agentName, localizeString, callChangeCustomer)
        const { cancelled = false, rengaId: rengaIdFromDB = "", orgId: orgIdFromDB = "", firstName: firstNameFromDB = "", lastName: lastNameFromDB = "", email: customerEmailFromDB = "", phone: phoneFromDB = "" } = response
        if (cancelled) {
          console.log("ecm: Auth flow Blocking APIs")
          return;
        }
        if (rengaIdFromDB && orgIdFromDB && firstNameFromDB && lastNameFromDB) {
          rengaId = rengaIdFromDB
          orgId = orgIdFromDB
          firstName = firstNameFromDB
          lastName = lastNameFromDB
          customerEmail = customerEmailFromDB
          customerPhone = phoneFromDB
          this.props.setActiveConversationData({
            rengaId,
            orgId,
            firstName,
            lastName,
            conversationId,
            agentName,
            customerEmail,
            agentEmail,
            phone: customerPhone
          })
          this.setState({ showBackButton: true })
        }
      } else {
        const response: any = await this.unAuthenticatedUser(conversationId, localizeString);
        const { cancelled = false, rengaId: rengaIdFromDB = "", orgId: orgIdFromDB = "", firstName: firstNameFromDB = "", lastName: lastNameFromDB = "", email: customerEmailFromDB = "", phone: phoneFromDB = "" } = response
        if (cancelled) {
          console.log("ecm: unAuth flow Blocking APIs")
          return;
        }
        if (rengaIdFromDB && orgIdFromDB && firstNameFromDB && lastNameFromDB) {
          rengaId = rengaIdFromDB
          orgId = orgIdFromDB
          firstName = firstNameFromDB
          lastName = lastNameFromDB
          customerEmail = customerEmailFromDB
          customerPhone = phoneFromDB
          this.props.setActiveConversationData({
            rengaId,
            orgId,
            firstName,
            lastName,
            conversationId,
            agentName,
            customerEmail,
            agentEmail,
            phone: customerPhone
          })
          this.setState({ showBackButton: true })
        } else {
          setSessionStorage(
            this.state.currentConversationId,
            'enableSearchCustomer',
            true
          )
          this.setCurrentViewToCache(false, true, false)
          this.setState({
            showSearchCustomer: true
          })
        }
      }

      const cacheData = getSessionStorage(conversationId)
      console.log("ecm: status", status);
      const { currentPage = '', cachedRengaId = '', cachedOrgId = '' } = cacheData;
      if ((!currentPage) && (rengaId && orgId)) {
        this.setState({ showCaseListPage: true, showSearchCustomer: false, pageReady: true })
        this.toggleLoader(false, '')
        this.setCurrentViewToCache(true, false, false);
      }
      if (currentPage === 'createCase') {
        this.setState({ showCaseListPage: false, showSearchCustomer: false, pageReady: true })
        this.toggleLoader(false, '')
      }
      if (currentPage === 'searchCustomer' || (!currentPage && (!rengaId && !orgId))) {
        this.setState({ showSearchCustomer: true, showCaseListPage: false, pageReady: true })
        if (cachedRengaId && cachedOrgId) {
          this.setState({ showBackButton: true })
        }
        this.toggleLoader(false, '')
      }
      if (currentPage === 'caseList') {
        this.setState({ showCaseListPage: true, showSearchCustomer: false, pageReady: true })
        this.toggleLoader(false, '')
      }

    } catch (err) {
      // Toggle loader to false if API calls had error
      if (err && err.message && err.message.includes('ECM API calls cancelled on umnmount')) {
        console.log('ecm: set customer calls cancelled for conversationId', conversationId, err)
      } else {
        console.error('ecm: set customer error', err);
        this.toggleLoader(false, '')
      }
    }
  }

  /* istanbul ignore next */
  toggleLoader = (flag: boolean, message: string) => {
    this.setState({ isLoading: flag, loaderMessage: message })
  }

  /* istanbul ignore next */
  toggleCaseListView = (flag: boolean) => {
    this.setState({ showCaseListPage: flag })
    if (flag)
      this.setCurrentViewToCache(true, false, false);
    else
      this.setCurrentViewToCache(false, false, true);
  }

  /* istanbul ignore next */
  // Need to toggle both search customer and case list
  toggleSearchCustomerView = (flag: boolean) => {
    setSessionStorage(
      this.state.currentConversationId,
      'enableSearchCustomer',
      true
    )
    this.setState({
      showSearchCustomer: flag,
      showBackButton: flag,
      showCaseListPage: !flag
    })
    if (flag) {
      // this.setState({ showCaseListPage: false });
      this.setCurrentViewToCache(false, true, false);
    } else {
      this.setCurrentViewToCache(true, false, false);
    }
  }

  setCurrentViewToCache = (isCaseView: boolean, isSearchView: boolean, isCreateView: boolean) => {
    if (isCaseView) {
      setSessionStorage(this.state.currentConversationId, 'currentPage', 'caseList')
    }
    if (isSearchView) {
      setSessionStorage(this.state.currentConversationId, 'currentPage', 'searchCustomer')
    }
    if (isCreateView) {
      setSessionStorage(this.state.currentConversationId, 'currentPage', 'createCase')
    }
  }
  /* istanbul ignore next */
  renderLoader = (styles: any) => (
    <div className={styles['main-loader']}>
      <ProgressCircle aria-label='Loading…' isIndeterminate />
      <div className={styles['main-loader-label']}>
        {this.state.loaderMessage}
      </div>
    </div>
  )

  /* istanbul ignore next */
  navigateToCaseList = () => {
    setSessionStorage(
      this.state.currentConversationId,
      'enableSearchCustomer',
      false
    )
    this.toggleSearchCustomerView(false);
    this.setState({
      showZendeskPage: false,
      showNavigateToDynamicsPage: false,
      showCaseListPage: true
    })
    this.setCurrentViewToCache(true, false, false);
  }


  /* istanbul ignore next */
  toggleRenderComponent = (styles: any) => {
    const {
      showNavigateToDynamicsPage,
      isLoading,
      pageReady,
      showCaseListPage,
      showSearchCustomer,
      showZendeskPage
    } = this.state
    if(showZendeskPage) return <Zendesk url={this.zendeskUrl}/>
    if (showNavigateToDynamicsPage) return <EnterpriseCaseView />
    if (showSearchCustomer)
      return (
        <SearchCustomer
          updateContextData={this.updateContextData}
          contextData={parseContextData(this.props)}
          darkMode={this.props.darkMode}
          language={this.props.language}
          handleGoback={this.navigateToCaseList}
          showBackButton={this.state.showBackButton}
          toggleLoader={this.toggleLoader}
          toggleSearchCustomerView={this.toggleSearchCustomerView}
        />
      )
    if (isLoading) return this.renderLoader(styles)
    else if (!pageReady) return ''
    else if (showCaseListPage)
      return (
        <CaseList
          handleViewChange={this.toggleCaseListView}
          handleSearchView={this.toggleSearchCustomerView}
        />
      )
    else return <CreateCase handleViewChange={this.toggleCaseListView} />
  }

  render() {
    const styles = this.props.darkMode ? darkTheme : lightTheme
    console.log(this.state, 'currentState')
    return (
      // move create case component to caseList page
      <div className={styles['main-container']}>
        {this.props.toggleNotification && (
          <Notification message={this.props.notificationMessage} />
        )}
        <div className={styles['theme']}>
          <CaseHeader handleRefresh={this.refreshWidget} />
          <ChangeCustomer callbackEvent={this.props.callBackEvent} />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {this.toggleRenderComponent(styles)}
          </ErrorBoundary>
        </div>
      </div>
    )
  }
}

function ErrorFallback() {
  return (
    <div role='alert' style={{ padding: '0px 1.25rem 0px 1.25rem' }}>
      <p>Something went wrong:</p>
      <pre>Please refresh the widget</pre>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  isCaseListPage: state.uiState.isCaseListPage,
  darkMode: state.uiState.darkMode,
  language: state.uiState.language,
  toggleNotification: state.uiState.toggleNotification,
  notificationMessage: state.uiState.notificationMessage,
  showSearchCustomer: state.uiState.showSearchCustomer,
  conversationIdArray: state.ocState.conversationIdArray,
  allowToLinkCaseForReturnCustomer: state.ocState.allowToLinkCaseForReturnCustomer
})

const mapDispatchToProps = (dispatch: any) => ({
  setActiveConversationData: function (data: any) {
    dispatch(setActiveConversationData(data))
  },
  setContextDataDispatch: function (data: any) {
    dispatch(setContextData(data))
  },
  setCaseListDispatch: function (data: any) {
    dispatch(setCaseList(data))
  },
  setCustomerDispatch: function (data: any, localizeString: any, agentDetails: any, callChangeCustomer?: boolean) {
    dispatch(setLoadCustomerData(data, localizeString, agentDetails, callChangeCustomer))
  },
  clearUIStateDispatch: function (data: any) {
    dispatch(clearUIState(data))
  },
  clearCaseStateDispatch: function (data: any) {
    dispatch(clearCaseState(data))
  },
  clearOCStateDispatch: function (data: any) {
    dispatch(clearOCState(data))
  },
  clearCustomerStateDispatch: function (data: any) {
    dispatch(clearCustomerState(data))
  },
  toggleDarkMode: function (flag: boolean) {
    dispatch(toggleDarkMode(flag))
  },
  setLanguage: function (language: string) {
    dispatch(setLanguage(language))
  },
  setServiceBaseUrl: function (url: string) {
    dispatch(setServiceBaseUrl(url))
  },
  setAgentConsoleServiceBaseUrl: function (url: string) {
    dispatch(setAgentConsoleServiceBaseUrl(url))
  },
  setCustomerDeeplinkUrl: function (url: string) {
    dispatch(setCustomerDeeplinkURL(url))
  },
  setCaseDeeplinkUrl: function (url: string) {
    dispatch(setCaseDeeplinkURL(url))
  },
  setReadOnlyModeDispatch: function (flag: boolean) {
    dispatch(setReadOnlyMode(flag))
  },
  setActiveCaseDispatch: function (payload: any) {
    dispatch(setActiveCase(payload))
  },
  setCustomerDetailsFromDBDispatch: function (payload: any) {
    dispatch(setCustomerDetailsFromDB(payload))
  },
  toggleNotificationDispatch: function (flag: boolean, message: string) {
    dispatch(toggleNotification(flag, message))
  },
  setCreateCaseParamsDispatch: function (payload: any) {
    dispatch(setCreateCaseParams(payload))
  },
  setGuidForCustomerDeeplinkDispatch: function (payload: any) {
    dispatch(setGuidForCustomerDeeplink(payload))
  },
  setChangeCustomerFlagDispatch: function (flag: boolean) {
    dispatch(setChangeCustomer(flag))
  },
  setPersistantCustomerDispatch: function (payload: any) {
    dispatch(setPersistantCustomerDetails(payload))
  },
  setCaseCreatedFlagDispatch: function (flag: boolean) {
    dispatch(setCaseCreatedFlag(flag))
  },
  setConversationArrayDispatch: function (payload: Array<string>) {
    dispatch(setConversationArray(payload))
  },
  setReturnCustomerFlagDispatch: function (flag: boolean) {
    dispatch(allowToLinkCaseForReturnCustomer(flag))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
