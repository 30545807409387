const initialState: any = {
  contactList: {},
  caseNotesEligible: false,
  notesClosedConversation: {},
};

type actionType = {
  type: string;
  payload: any;
};

const notesReducer = (
  state: any = initialState,
  action: actionType = { type: '', payload: {} },
) => {
  const stateContactListCopy = { ...state.contactList };
  const notesCopy = { ...state.notesClosedConversation };
  switch (action.type) {
    case 'UPDATE_AGENT_NOTES_Collapsed_STATE': {
      const { currentContactId, agentNotesCollapsed } = action.payload;
      stateContactListCopy[currentContactId] = { agentNotesCollapsed };
      return {
        ...state,
        contactList: stateContactListCopy,
      };
    }
    case 'SET_ELIGIBILITY_CASE_NOTES_FLAG': {
      return {
        ...state,
        caseNotesEligible: action.payload,
      };
    }
    case 'SET_AGENT_NOTES_CLOSED_CONVERSATION': {
      const { currentContactId = '', notes = '' } = action.payload;
      notesCopy[currentContactId] = { notes };
      return {
        ...state,
        notesClosedConversation: notesCopy,
      };
    }
    case 'SET_UPDATED_TO_MILO': {
      return {
        ...state,
        updatedToMilo: action.payload,
      };
    }
    default:
      return state;
  }
};

export default notesReducer;
